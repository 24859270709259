import React, { useEffect, useState } from 'react'

import { Paper, TableBody, TableRow, TableCell, Toolbar, InputAdornment,Container,Grid, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Title from './Title';
import {useTable, UsersTable} from "../../components/useTable";
//import {useUsersTable} from "../../components/useTable";
import * as employeeService from "../../services/employeeService";
import Controls from "../../components/controls/Controls";
import { Search } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddIcon from '@mui/icons-material/Add';
import Popup from "../../components/Popup";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import BrandForm from "./Forms/BrandForm";
import axios from "axios";

const useStyles = makeStyles(theme => ({
    pageContent: {
        width:"100%"
        // margin: theme.spacing(5),
        // padding: theme.spacing(3)
    },
    searchInput: {
        width: '75%'
    },
    newButton: {
        position: 'absolute',
        right: '10px'
    },
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4),
      },
}))


const headCells = [
    { id: 'accode', label: 'Account Code',disableSorting: true},
    { id: 'grpcode', label: 'Group Code', disableSorting: true },
    { id: 'parentcd', label: 'Parent Code', disableSorting: true },
    { id: 'brandname', label: 'Brand Name', disableSorting: true},
    { id: 'grpname', label: 'Group Name', disableSorting: true },
    { id: 'sequence', label: 'Sequence', disableSorting: true },
    { id: 'paylater', label: 'pay-later', disableSorting: true},
    { id: 'actions', label: 'Actions', disableSorting: true },
];

const usersHeadCells = [
    { id: 'srno', label: 'SR-NO.',disableSorting: true},
    { id: 'username', label: 'Username', disableSorting: false },
    { id: 'emailid', label: 'Email-id', disableSorting: true },
    { id: 'mobileno', label: 'Mobile-No', disableSorting: true},
    { id: 'actions', label: 'Delete User', disableSorting: true },
];

export default function CustomersManage() {

    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [customerRecords, setCustomerRecords] = useState([]);
    const [usersRecords, setUsersRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [usersFilterFn, setUsersFilterFn] = useState({ fn: items => { return items; } });
    const [openPopup, setOpenPopup] = useState(false)
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const [showProducts, setShoProducts] = useState(false);
    const [showUsers, setShowUsers] = useState(false);
    const [userDeleted, setUserDeleted] = useState(false);
    const [upload,setUpload]= useState(false);
    const pages = [5, 10, 25]
    useEffect(()=>{
        if(!userDeleted) {
            getAllBrands();
        }
        getAllUsers();
    },[upload,userDeleted])

    const getAllBrands =async()=> {
        const config = {
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        await axios.get("getAllBrands",config).then(
         response => {
            setCustomerRecords(response.data);
            }
        )
        .catch(error => {
         alert("Error getting Brand Data! 👎");
            return [];
        });
    }
    const getAllUsers =async()=> {
        setUserDeleted(false);
        const config = {
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        await axios.get("getAllUsers",config).then(
         response => {
            setUsersRecords(response.data);
            }
        )
        .catch(error => {
         alert("Error getting Users Data! 👎");
            return [];
        });
    }
    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(customerRecords,pages, headCells, filterFn);

    const {
        UsersTblContainer,
        UsersTblHead,
        UsersTblPagination,
        usersRecordsAfterPagingAndSorting
    } = UsersTable(usersRecords,pages, usersHeadCells, usersFilterFn);
    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value == "")
                    return items;
                else
                    return items.filter(x => x.brandname.toLowerCase().includes(target.value))
            }
        })
    }
    const handleUserSearch = e => {
        let target = e.target;
        setUsersFilterFn({
            fn: items => {
                if (target.value == "")
                    return items;
                else
                    return items.filter(x => x.username.toLowerCase().includes(target.value))
            }
        })
    }

    const addOrEdit = async(Brand, resetForm) => {
        const config ={
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        // const data={

        // }
        await axios.post("brandSubmit",Brand,config).then(
         response => {
                if(response.data){
                    resetForm();
                     setNotify({
                    isOpen: true,
                    message: 'Submitted Successfully',
                    type: 'success'
                    })
                }else{
                    
                }
            }
        )
        .catch(error => {
         alert("Server Error ! 👎");
            return [];
        });
        // if (employee.id == 0)
        //     employeeService.insertEmployee(employee)
        // else
        //     employeeService.updateEmployee(employee)
        // resetForm()
        // setRecordForEdit(null)
        setOpenPopup(false);
        setUpload(!upload);
        // setRecords(employeeService.getAllEmployees())
        // setNotify({
        //     isOpen: true,
        //     message: 'Submitted Successfully',
        //     type: 'success'
        // })
    }

    const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }

    const onDelete = async(id )=> {
        await employeeService.deleteBrand(id);
        setNotify({
            isOpen: true,
            message: 'Deleted Successfully',
            type: 'error'
        });
        setUpload(!upload);
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
    }

    const onDeleteUser = async(id )=> {
        await employeeService.deleteUser(id);
        setNotify({
            isOpen: true,
            message: 'Deleted Successfully',
            type: 'error'
        });
        setUserDeleted(true);
        setUpload(!upload);
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
    }

    const handleClickOnPayLaterButton = async(event) => {
        const {id, checked} = event.target;
        let item = customerRecords.filter(rec => rec.id == id);
        const config ={
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const dataObj={
            ...item[0],
            paylater : item[0].paylater ? false : true
        }
        await axios.post("brandSubmit",dataObj,config).then(
         response => {
                if(response.data){
                  let index = customerRecords?.findIndex(rec => rec.id == item[0].id);
                  if(index != undefined && index != -1) {
                    let list = [...customerRecords];
                    list[index].paylater = dataObj.paylater;
                    setCustomerRecords(list); 
                  }
                }
            }
        )
        .catch(error => {
         alert("Server Error ! 👎");
        });

    }

    return (
        <>
            {/* <PageHeader
                title="Vasants Labels Pvt. Ltc."
                subTitle="Production Management"
                icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
            /> */}
            <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
            <Grid item xs={12}>
            <Paper className={classes.paper}>
                {/* <Controls.Button
                    text="Add"
                    color="default" 
                    onClick={handleSubmitShowProducts}
                /> */}
                <Title>Manage Brand (ADD or EDIT)</Title>
                {
                  showProducts ? <ArrowDropUpIcon onClick={()=>{setShoProducts(!showProducts)}} /> : <ArrowDropDownIcon onClick={()=>{setShoProducts(!showProducts)}} />
                }
                {
                  showProducts ? 
                  <>
                  <Toolbar>
                    <Controls.Input
                        label="Search Brand"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                    <Controls.Button
                        text="Add New"
                        variant="outlined"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    />
                </Toolbar>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item.id}>
                                    <TableCell>{item.accode}</TableCell>
                                    <TableCell>{item.grpcode}</TableCell>
                                    <TableCell>{item.parentcd}</TableCell>
                                    <TableCell>{item.brandname}</TableCell>
                                    <TableCell>{item.grpname}</TableCell>
                                    <TableCell>{item.sequence}</TableCell>
                                    <TableCell> <Switch id={item.id} checked={item.paylater ? true : false} onChange={handleClickOnPayLaterButton} color="primary"/></TableCell>
                                    <TableCell>
                                        <Controls.ActionButton
                                            color="primary"
                                            onClick={() => { openInPopup(item) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                        <Controls.ActionButton
                                            sx={{ color: 'secondary.main' }}
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Are you sure to delete this record?',
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => { onDelete(item.id) }
                                                })
                                            }}>
                                            <CloseIcon fontSize="small" />
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
                </>
                : null
                }
                
            </Paper>
            </Grid>

            {/* Users Controls (delete) */}
            <Grid item xs={12}>
            <Paper className={classes.paper}>
                {/* <Controls.Button
                    text="Add"
                    color="default" 
                    onClick={handleSubmitShowProducts}
                /> */}
                <Title>Manage Users</Title>
                {
                  showUsers ? <ArrowDropUpIcon onClick={()=>{setShowUsers(!showUsers)}} /> : <ArrowDropDownIcon onClick={()=>{setShowUsers(!showUsers)}} />
                }
                {
                  showUsers ? 
                  <>
                <Toolbar>
                    <Controls.Input
                        label="Search Users"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleUserSearch}
                    />
                    {/* <Controls.Button
                        text="Add New"
                        variant="outlined"
                        startIcon={<AddIcon />}
                        className={classes.newButton}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    /> */}
                </Toolbar>
                <UsersTblContainer>
                    <UsersTblHead />
                    <TableBody>
                        {
                            usersRecordsAfterPagingAndSorting().map((item,ind) =>
                                (<TableRow key={ind}>
                                    <TableCell>{ind}</TableCell>
                                    <TableCell>{item.username}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.mobileNo}</TableCell>
                                    {/* <TableCell>{item.grpname}</TableCell>
                                    <TableCell>{item.sequence}</TableCell> */}
                                    <TableCell>
                                        <Controls.ActionButton
                                            sx={{ color: 'secondary.main' }}
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Are you sure to delete this record?',
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => { onDeleteUser(item.id) }
                                                })
                                            }}>
                                            <CloseIcon fontSize="small" />
                                        </Controls.ActionButton>
                                    </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </UsersTblContainer>
                <UsersTblPagination />
                </>
                : null
                }
                
            </Paper>
            </Grid>
            </Grid>
                
            </Container>
            <Popup
                title="Employee Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
            <BrandForm addOrEdit={addOrEdit} recordForEdit={recordForEdit}/>
                {/* <EmployeeForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} /> */}
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}