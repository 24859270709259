import React, { useState, useEffect } from 'react'
import { Grid, } from '@mui/material';
import Controls from "../../controls/Controls";
import { Form } from '../../useForm';

const initialFValues = {
   accode:"",
   grpcode:"",
   parentcd:"",
   brandname:"",
   grpname:"",
   sequence:""
}

export default function BrandForm(props) {
    const { addOrEdit, recordForEdit } = props;
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])
    
    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('accode' in fieldValues)
            temp.accode = fieldValues.accode ? "" : "This field is required."
        if ('grpcode' in fieldValues)
            temp.grpcode = fieldValues.grpcode ? "" : "This field is required."
        if ('parentcd' in fieldValues)
            temp.parentcd = fieldValues.parentcd ? "" : "This field is required."
        if ('brandname' in fieldValues)
            temp.brandname = fieldValues.brandname ? "" : "This field is required.."
        if ('grpname' in fieldValues)
            temp.grpname = fieldValues.grpname ? "" : "This field is required."
            if ('sequence' in fieldValues)
            temp.sequence = fieldValues.sequence ? "" : "This field is required."
    
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }
    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Input
                        name="accode"
                        label="Account Code"
                        value={values.accode}
                        onChange={handleInputChange}
                        error={errors.accode}
                    />
                    <Controls.Input
                        name="grpcode"
                        label="Group Code"
                        value={values.grpcode}
                        onChange={handleInputChange}
                        error={errors.grpcode}
                    />
                    <Controls.Input
                        name="grpname"
                        label="Group Name"
                        value={values.grpname}
                        onChange={handleInputChange}
                        error={errors.grpname}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        label="Parent Code"
                        name="parentcd"
                        value={values.parentcd}
                        onChange={handleInputChange}
                        error={errors.parentcd}
                    />
                    <Controls.Input
                        label="Brand Name"
                        name="brandname"
                        value={values.brandname}
                        onChange={handleInputChange}
                        error={errors.brandname}
                    />
                   <Controls.Input
                        label="Sequence"
                        name="sequence"
                        value={values.sequence}
                        onChange={handleInputChange}
                        error={errors.sequence}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <Controls.Button
                            type="submit"
                            text="Submit" />
                        <Controls.Button
                            text="Reset"
                            sx={{ color: 'primary.main' }}
                            onClick={resetForm} />
                    </div>
                </Grid>
            </Grid>
        </Form>
    )
}
