import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CartItem from '../cart-item/cart-item.component';



import {
  CartDropdownContainer,
  CartDropdownButton,
  EmptyMessageContainer,
  CartItemsContainer
} from './cart-dropdown.styles';
import { toggleHidden } from '../../store/slices/cart/CartSlice.jsx';
import { useNavigate } from 'react-router-dom/dist';

export const CartDropdown = () => {
const navigate = useNavigate()
  const cartItems = useSelector((state) => state.cart.cartItems);
  const zeroValueCartItems = useSelector((state) => state.cart.zeroValueCartItems);
  const dispatch = useDispatch();

  return(
  <CartDropdownContainer>
    <CartItemsContainer>
        {
          cartItems?.map((items, ind) => (
            <CartItem key={ind} item={items} />
          ))
        }
        {
          zeroValueCartItems?.map((items, ind) => (
            <CartItem key={ind} item={items} />
          ))
        }

        {
          !(cartItems?.length || zeroValueCartItems?.length) && <EmptyMessageContainer>Your cart is empty</EmptyMessageContainer>
        }
    </CartItemsContainer>
    <CartDropdownButton
      onClick={() => {
        navigate('/checkout');
        dispatch(toggleHidden());
      }}
    >
      GO TO CHECKOUT
    </CartDropdownButton>
  </CartDropdownContainer>
);
};

export default (CartDropdown);