import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import CollectionPreview from '../../components/collection-preview/collection-preview.component';
import Spinner from "../../components/spinner/spinner.component";

import axios from 'axios';
import { setShoppingData } from '../../store/slices/shop/ShopSlice';
import CartItem from '../../components/cart-item/cart-item.component';

const CategoriesData  = ({displayVlplItems, filterFn, infletedRateApply, index, cat, selectedRootCategory}) => {
  
  /** redux */
  const shoppingData = useSelector((state) => state.shop.shoppingData);
  const dispatch = useDispatch();
  
  const [spinnerActive, setSpinnerActive] = useState(true);
  const [categoriesData, setCategoriesData] = useState([]);
  useEffect(()=>{
    getRecordBasedOnCategory();
  },[])

  const getRecordBasedOnCategory = async() => {
    const config ={
       headers:{Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    if (cat) {
        const existingCategoryItem = shoppingData.find(
            catItem => catItem.cat === cat && catItem.rootCategory === selectedRootCategory?.label
          );
        if(existingCategoryItem) {
            setCategoriesData(existingCategoryItem.data);
        } else {
            await axios.get(`productsBasedOnCategory/${cat}`,!displayVlplItems && config).then(
                response => {
                    let record = {
                        cat: `${cat}`,
                        rootCategory: selectedRootCategory?.label,
                        data: response.data
                    }
                    dispatch(setShoppingData([
                        record ,
                        ...shoppingData
                    ]));
                    setCategoriesData(response.data);
                    setSpinnerActive(false);
              }
              )
            .catch(error =>{
                setSpinnerActive(false);
                alert("Category data is missing");
                setCategoriesData(shoppingData);
            })
        }
      
    } else {
        setSpinnerActive(false);
        alert("cat is missing");
        setCategoriesData(shoppingData);
    }
   }
    return (
            categoriesData?.length > 0 ? 
            <CollectionPreview infletedRateApply={infletedRateApply} key={index} filterFn={filterFn} cat={cat} categoriesData={categoriesData} />
            :
            <>
              {
                spinnerActive ? 
                <Spinner />
                : null
              }
            </>
    );
};


export default CategoriesData;