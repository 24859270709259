import { createSelector } from '@reduxjs/toolkit';

const selectCart = state => state.cart;

export const selectCartItems = createSelector(
  [selectCart],
  cart => cart.cartItems
);

export const selectCorefNo = createSelector(
  [selectCart],
  cart => cart.corefno
);

export const selectCartHidden = createSelector(
  [selectCart],
  cart => cart.hidden
);

export const selectUpdateRateIntoInflatedRate = createSelector(
  [selectCart],
  cart => cart.updateRateIntoInflatedRate
);

export const selectCartItemsCount = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
        accumalatedQuantity + cartItem.quantity,
      0
    )
);

export const selectCartTotal = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce((accumulatedTotal, cartItem) => {
      const rateInRupees = cartItem.rate / 100;
      const itemTotal = cartItem.quantity * rateInRupees;
      return accumulatedTotal + itemTotal;
    }, 0)
);

export const selectCartOrGSTOrShippingTotal = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
      (((parseFloat(accumalatedQuantity)/parseFloat(cartItem.linebrk)) + (cartItem.quantity/parseFloat(cartItem.linebrk))) * parseFloat(cartItem.rate)) + ((((parseFloat(accumalatedQuantity)/parseFloat(cartItem.linebrk)) + (cartItem.quantity/parseFloat(cartItem.linebrk))) * parseFloat(cartItem.rate)) * 0.15) + 250 ,
      0
    )
);
