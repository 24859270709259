import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/user/UserSlice";
import CartSlice from "./slices/cart/CartSlice";
import ShopSlice from "./slices/shop/ShopSlice";

const store = configureStore({
  reducer: {
    users: userSlice,
    cart: CartSlice,
    shop: ShopSlice
  },
});

export default store;
