
import { Grid, Typography, Button, TextField, Paper, Tabs, Tab, Box, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, Input, DialogContentText } from '@mui/material';
import { makeStyles } from '@mui/styles';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import Header from "../../components/Header";
import Notification from "../../components/Notification";
import Controls from "../../components/controls/Controls";
import DetailsThumb from '../../components/productdetail/DetailsThumb';
import Spinner from "../../components/spinner/spinner.component";
import './ProductDetail.component.css';

import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Popup from '../../components/Popup';
import { addItemToCartItems, updateCorefNo } from '../../store/slices/cart/CartSlice';
import ProductDetailsPdf from './ProductDetailsPdf';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom/dist';
import { categoryArray } from '../../constants/VlplConstants';
import { Label } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  mainImage: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  thumbnail: {
    cursor: 'pointer',
    width: '40px',
    // height: '80px',
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  productDetails: {
    paddingLeft: theme.spacing(4),
  },
  section: {
    marginTop: theme.spacing(2),
  },
  tabs: {
    marginTop: theme.spacing(4),
  },
  tabContent: {
    padding: theme.spacing(2),
  },
  infomsg: {
    color: 'red',
    width: '100%',
    paddingTop: '10px'
  },
  warmsg: {
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '5px'
  },
}));

const selectedQuantityAndSizeDefaultValue = {
  isOpen: false,
  selectedSize: '',
  quantity: 0,
  error:''
}

const ProductDetailPage = (props) => {

  const classes = useStyles();
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  /** redux */
  const corefno = useSelector((state) => state.cart.corefno);
  const currentUser = useSelector((state) => state.users.currentUser);
  const rootCatagory = useSelector((state) => state.shop.rootCategory);
  const dispatch = useDispatch();

  const { id } = useParams();

  const [spinnerActive, setSpinnerActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [tempSelectedFile, setTempFile] = useState();

  const [state, setState] = useState({
    index: 0
  });
  const [linebrk, setLinebrk] = useState();
  const [status, setStatus] = useState(false);
  /** This flag is set when brand is babyhug to just show info message to the user as per add selection rate changes */
  const [infletedRateApply, setinfletedRateApply] = useState(false);
  // const [cityNotApplyInflation,setCityNotApplyInflation]=useState(["V018","V001","T040","C038","C031","B148","B001"]);  //yaha par badme data get karke set karna hai axios se
  const [values, setValues] = useState({
    size: "",
    // corefno:corefno
  });

  const [itemlist, setItemlist] = useState(null);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [errors, setErrors] = useState({});

  const myRef = React.createRef();


  const [updatedQuantity, setupdatedQuantity] = useState(0);
  const [updatedQuantityError, setupdatedQuantityError] = useState(false);
  const [sizeAndQuantity, setSizeAndQuantity] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openFileUploadPopup, setOpenFileUploadPopup] = useState(false);
  const [selectedSizeAndQuantity, setSelectSizeAndQuantity] = useState(selectedQuantityAndSizeDefaultValue);
  const [updateQuantityInterval, setupdateQuantityInterval] = useState();
  const [openZeroValueInfoPopup, setOpenZeroValueInfoPopup] = useState(false);

  const { index } = state;
  useEffect(() => {
    setTimeout(() => {
      getProductDetails();
    }, 0);
  }, [])

  useEffect(() => {
    if (selectedSizeAndQuantity.isOpen) {
      setSelectSizeAndQuantity(selectedQuantityAndSizeDefaultValue);
      handleAddtoCart(undefined);
    }
  }, [sizeAndQuantity]);

  const getProductDetails = async () => {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    console.log(rootCatagory);
    await axios.get(`getProductDetails/${id}`, (rootCatagory !== categoryArray[0].label) && config)
      .then(response => {
        if (response?.data) {

          response.data = {
            ...response.data,
            ['sizes']: response.data?.sizes ? JSON.parse(response.data?.sizes) : null,
            ['questionnaire']: response.data.questionnaire ? JSON.parse(response.data.questionnaire) : null,
          };
          setItemlist(response.data);
          setLinebrk(response.data.linebrk);
          if(Number(response.data?.rate) === 0) {
            setOpenZeroValueInfoPopup(true);
          }
          setSpinnerActive(false);

          if (response.data.ordertype.toLowerCase() === "with additional sheet") {
            setStatus(true);
          }
          if (response?.data?.brand?.toLowerCase() === "babyhug") {
            setinfletedRateApply(true);
          }
        }
      }
      )
      .catch(error => {
        setSpinnerActive(false);
        alert(error);
      })
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('corefno' in fieldValues)
      temp.corefno = (fieldValues.corefno) ? "" : "required"
    setErrors({
      ...temp
    })

    if (fieldValues = values)
      return Object.values(temp).every(x => x == "")
  }

  const setNotificationWhileQuestionnaireCheck = (obj) => {
    let fileNeedToUpload = !selectedFile && itemlist.rate == 0; 
    /** additional file are olnt applicable when product is zero value */
    setNotify({
      isOpen: true,
      message: fileNeedToUpload  ? 'please select additional file' : `please provide answer for this question { ${obj.question} }`,
      type: fileNeedToUpload ? 'error' : 'warning'
    });
  }

  const checkQuestionnaireSubmittedOrNot = () => {
    let flag = true;  // Assume all checks will pass unless proven otherwise
    if(itemlist.rate == 0 && !selectedFile) {
      flag = false;
      setNotify({
        isOpen: true,
        message: 'please select additional file',
        type: 'error'
      });
    } else if (itemlist.questionnaire?.length) {
        for (let obj of itemlist.questionnaire) {
            if (obj.compulsoryWithPdf) {
                /** High order question */
                if (obj.userAnswer && (itemlist.rate != 0 || selectedFile)) {
                    continue;
                } else {
                    setNotificationWhileQuestionnaireCheck(obj);
                    flag = false;
                    break;
                }
            } else if (obj.compulsory) {
                /** medium order question */
                if (obj.userAnswer) {
                    continue;
                } else {
                    setNotificationWhileQuestionnaireCheck(obj);
                    flag = false;
                    break;
                }
            } else {
                /** optional question */
                continue;
            }
        }
    }
    return flag;
}


  const handleAddtoCart = (event) => {
    let name = '';
    if (event) {
      event.preventDefault();
      name = event.target.name;
    } else {
      name = "cart"
    }
    if (checkQuestionnaireSubmittedOrNot()) {
      if (status) {

        if (sizeAndQuantity && sizeAndQuantity.length) {
          getTaxTapAndAddToCart(name);
          setNotify({
            isOpen: true,
            message: 'quantity updated Successfully',
            type: 'success'
          });
        } else {
          setSelectSizeAndQuantity({ ...selectedSizeAndQuantity, ['isOpen']: true });
        }

      } else if(updatedQuantity >= itemlist.linebrk) {
        /** without additional sheet */
        getTaxTapAndAddToCart(name);
      } else if(updatedQuantity < itemlist.linebrk) {
        setNotify({
          isOpen: true,
          message: `Minimum Order Quantity is ${itemlist.linebrk}`,
          type: 'warning'
        });
      }
    }

  }

  const addItemsInCart = async (name) => {
    let listOfProductWithDifferentSizes = [];
    let additionalSheet = false;
    if (sizeAndQuantity && sizeAndQuantity.length) {
      additionalSheet = true;
      sizeAndQuantity.forEach(item => {
        listOfProductWithDifferentSizes.push({
          "product_id": itemlist.id,
          "docno": itemlist.docno,
          "ordinst1": itemlist.ordinst1,
          "itemcode": itemlist.itemcode,
          "selectedSize": item.selectedSize,
          "linebrk": itemlist.linebrk,
          "selectedQuantity": item.quantity,
          "createdAt": null,
          "corefno": corefno,
          "cgst": null,
          "sgst": null,
          "igst": null,
          "rate": itemlist.rate,
          "inflatedRate": itemlist.inflatedRate,
          "inflatedRateApplied": null,
          "finalRate": null,
          "finalInflatedRate": null,
          "finalRateWithTax": null,
          "acmastId": null,
          "questionnaire": itemlist.questionnaire ? JSON.stringify(itemlist.questionnaire) : null
        });
      });

    } else {
      additionalSheet = false;
      listOfProductWithDifferentSizes.push({
        "product_id": itemlist.id,
        "docno": itemlist.docno,
        "ordinst1": itemlist.ordinst1,
        "itemcode": itemlist.itemcode,
        "selectedSize": values.size,
        "linebrk": itemlist.linebrk,
        "selectedQuantity": updatedQuantity ? updatedQuantity : linebrk,
        "createdAt": null,
        "corefno": corefno,
        "cgst": null,
        "sgst": null,
        "igst": null,
        "rate": itemlist.rate,
        "inflatedRate": itemlist.inflatedRate,
        "inflatedRateApplied": null,
        "finalRate": null,
        "finalInflatedRate": null,
        "finalRateWithTax": null,
        "acmastId": null,
        "questionnaire": itemlist.questionnaire ? JSON.stringify(itemlist.questionnaire) : null
      });
    }
    if (listOfProductWithDifferentSizes && listOfProductWithDifferentSizes?.length) {
      if (currentUser?.username) {
        const config = {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        await axios.post(`endUser/cartDetails`, listOfProductWithDifferentSizes, config)
          .then(resData => {
            if (resData && resData.data) {
              handleListOfProductWithDifferentSizes(listOfProductWithDifferentSizes, additionalSheet, name);
            } else {
              if (name == "buynow") {
                navigate('/checkout');
              } else {
                currentUser?.username ? navigate('/') : navigate('/checkout');

              }
            }

          })
          .catch(e => {
            console.log(e);
            setNotify({
              isOpen: true,
              message: 'quantity updated Successfully',
              type: 'success'
            });
          });
      } else {
        /** non login user */
        handleListOfProductWithDifferentSizes(listOfProductWithDifferentSizes, additionalSheet, name);
      }
    }
  }

  const handleListOfProductWithDifferentSizes = (listOfProductWithDifferentSizes, additionalSheet, name) => {
    listOfProductWithDifferentSizes.forEach(item => {
      if (additionalSheet) {
        dispatch(addItemToCartItems({
          ...itemlist,
          questionnaire: item.questionnaire,
          selectedSize: item.selectedSize,
          quantity: item.selectedQuantity,
          selectedQuantity: item.selectedQuantity,
          corefno: corefno,
          selectedFile: selectedFile,
        }));
      } else {
        dispatch(addItemToCartItems({
          ...itemlist,
          questionnaire: item.questionnaire,
          selectedSize: values.size,
          quantity: updatedQuantity ? updatedQuantity : linebrk,
          selectedQuantity: item.selectedQuantity,
          corefno: corefno,
          selectedFile: selectedFile,
        }));
      }
    });
    if (name == "buynow") {
      navigate('/checkout');
    } else {
      currentUser?.username ? navigate('/') : navigate('/checkout');

    }
  }

  const getTaxTapAndAddToCart = async (name) => {
    if (currentUser?.username) {
      const config = {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      }
      await axios.get(`getTapTax/${itemlist.itemcode}`, config)
        .then(response => { // then print response status
          // console.log(response.data.cgst)
          addItemsInCart(name);
        })
        .catch(() => {
          alert("Tax is not available");
        });
    } else {
      /** non login user  */
      addItemsInCart(name);
    }

  }

  const handleInputChangeOnQuantity = (e) => {
    const { value } = e.target;

    let valueNumber = Number(value);
    if(valueNumber >= 0) {
      let num = valueNumber / linebrk;
      if (valueNumber >= linebrk) {
        setupdatedQuantityError(false);
        if(!itemlist.quantityModification) {
          if (num % 1 != 0) {
            let number = parseInt(num) + 1;
            setupdatedQuantity(linebrk * number);
          } else {
            setupdatedQuantity(valueNumber);
          }
        } else {
          setupdatedQuantity(valueNumber);
        }
      } else {
        setupdatedQuantityError(true);
      }
    } else {
      e.target.value = 0;
    }
  }

  const handleInputChangeOnQuantityAdditionalOperation = (e) => {
    const { id, value } = e.target;
    if (!isNaN(value)) {

      let num = value / linebrk;
      if (value >= linebrk) {
        setupdatedQuantityError(false);
        if (!itemlist.quantityModification && num % 1 != 0) {
          let number = parseInt(num) + 1;
          let sizeAndQuantityObject = {
            selectedSize: id,
            quantity: linebrk * number
          };

          setupdatedQuantity(linebrk * number);
          if (sizeAndQuantity.find(item => item.selectedSize == id)) {
            setSizeAndQuantity(
              sizeAndQuantity.map(item => {
                if (item.selectedSize == id) {
                  return { ...item, quantity: linebrk * number };
                } else {
                  return item;
                }
              })
            );
          } else {
            setSizeAndQuantity([...sizeAndQuantity, sizeAndQuantityObject]);
          }
        } else {
          setupdatedQuantity(value);
          let sizeAndQuantityObject = {
            selectedSize: id,
            quantity: value
          };
          if (sizeAndQuantity.find(item => item.selectedSize == id)) {
            setSizeAndQuantity(
              sizeAndQuantity.map(item => {
                if (item.selectedSize == id) {
                  return { ...item, quantity: value };
                } else {
                  return item;
                }
              })
            );
          } else {
            setSizeAndQuantity([...sizeAndQuantity, sizeAndQuantityObject]);
          }
        }


        console.log(sizeAndQuantity);
        console.log(id);
      } else {
        setupdatedQuantityError(true);
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateCorefNo(value));
  }

  const handleSelectSize = (e) => {
    const { value } = e.target;
    let tempObject = {
      ...selectedSizeAndQuantity,
      ['selectedSize']: value
    }
    setSelectSizeAndQuantity(tempObject);
  }

  const handleQuanntityForSelectedSize =(e)=> {
    const { value } = e.target;
    let tempObject = {
      ...selectedSizeAndQuantity,
      ['quantity']: value,
      ['error']: (Number(value) < itemlist.linebrk) ? `Minimum Order Quantity is ${itemlist.linebrk}` : ''
    };
    setSelectSizeAndQuantity(tempObject);

    if(updateQuantityInterval) {
      window.clearInterval(updateQuantityInterval);
    }
    if(value >= linebrk){
      let interval = setTimeout(() => {
        if(!itemlist.quantityModification && (value % linebrk !== 0)) {
          let num = parseInt(value/linebrk) + 1;
          let tempObject = {
            ...selectedSizeAndQuantity,
            ['quantity']: num * linebrk
          }
          setSelectSizeAndQuantity(tempObject);
        } else {
          let tempObject = {
            ...selectedSizeAndQuantity,
            ['quantity']: value
          }
          setSelectSizeAndQuantity(tempObject);
        }
      }, 1200);
      setupdateQuantityInterval(interval);
    }
  }

  const handleImageClick = (index) => {
    setMainImageIndex(index);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleSelectAnswer = (event, index) => {
    const { value } = event.target;
    let item = {...itemlist};
    item.questionnaire[index]= {...itemlist.questionnaire[index], userAnswer: value};
    setItemlist(item);
    console.log(item);
  }

  const handleInputAnswer = (event ,index) => {
    const { value } = event.target;
    let item = {...itemlist};
    item.questionnaire[index]= {...itemlist.questionnaire[index], userAnswer: value};
    setItemlist(item);
    console.log(item);
  }

  return (
    <>
      <Header searchInput={false} />
      {
        spinnerActive ?
          <Spinner /> :
          linebrk ?
          <React.Fragment>
            <Grid container className={classes.container}>
              <Grid item xs={12} md={5}>
                <Paper elevation={3}>
                  <img
                    src={`data:image/png;base64,${itemlist.images[mainImageIndex]}`}
                    alt="Product"
                    className={classes.mainImage}
                  />
                  <Grid sx={{ display: { sm: '', md: 'none' } }} container justifyContent="center">
                    {itemlist?.images.map((img, index) => (
                      <img
                        src={`data:image/png;base64,${img}`}
                        alt={`Thumbnail ${index + 1}`}
                        key={index}
                        className={classes.thumbnail}
                        onClick={() => handleImageClick(index)}
                      />
                    ))}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={7} className={classes.productDetails}>
                <Typography className={classes.infomsg}>
                  "Please note that the product price will vary based on your shipping address."
                </Typography>
                <Typography variant="h4" component="h1">
                  {itemlist.docno}
                </Typography>
                <Typography variant="h6" component="h2">
                  ₹{itemlist.rate ? (itemlist.rate / 100) : itemlist.rate}
                </Typography>
                <Typography variant="body1" component="p" className={classes.section}>
                  {itemlist.ordinst1}
                </Typography>
                <Typography variant="body1" component="p" className={classes.section}>
                  {itemlist.ordinst2}
                </Typography>
                {
                  itemlist.ordinst3 &&
                  <Typography variant="body1" component="p" className={classes.section}>
                    {itemlist.ordinst3}
                  </Typography>
                }
                {
                  itemlist.ordinst4 &&
                  <Typography variant="body1" component="p" className={classes.section}>
                    {itemlist.ordinst4}
                  </Typography>
                }

                <Grid sx={{ display: { xs: 'none', md: 'flex' } }} item xs={12}>
                  {itemlist?.images.map((img, index) => (
                    <img
                      src={`data:image/png;base64,${img}`}
                      alt={`Thumbnail ${index + 1}`}
                      key={index}
                      className={classes.thumbnail}
                      onClick={() => handleImageClick(index)}
                    />
                  ))}
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12}>
                    <div className='pt-2'> 
                      {
                        itemlist.linebrk !== 1 ?
                          <Typography className={classes.warmsg}>
                            {`Note** : This product requires a minimum order quantity of "${itemlist.linebrk}"`}
                          </Typography> : ""
                      }
                      {
                        (itemlist.ordertype) == "With additional sheet" ?
                          <>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>SrNo</TableCell>
                                  <TableCell>Size</TableCell>
                                  <TableCell>Quantity</TableCell>

                                  {/* <TableCell>Sumbit</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  itemlist.sizes.map((item, ind) => (
                                    <TableRow key={ind}>
                                      <TableCell>{ind}</TableCell>
                                      <TableCell className={classes.Size}>{item}</TableCell>
                                      <TableCell className={classes.Quantity}>
                                        <Controls.Input
                                          name="updatedQuantity"
                                          id={item}
                                          onChange={handleInputChangeOnQuantityAdditionalOperation}
                                          className={classes.Input}
                                        />
                                      </TableCell>

                                    </TableRow>
                                  ))
                                }

                              </TableBody>
                            </Table>
                          </>
                          :
                          <TextField
                            label="Enter Quantity"
                            type="number"
                            name="updateQuantity"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleInputChangeOnQuantity}
                            className={classes.section}
                            error={updatedQuantityError}
                            helperText={
                              updatedQuantityError ? `Minimum Quantity for this product is ${itemlist.linebrk}`
                                : ''
                            }
                            required
                          />
                      }
                    </div>
                  </Grid>

                  <Grid item>
                    <Button name="cart" onClick={(e)=>{handleAddtoCart(e)}} variant="contained" color="primary">
                      Add to Cart
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button name="buynow" onClick={(e)=>{handleAddtoCart(e)}} variant="contained" color="secondary">
                      Buy Now
                    </Button>
                  </Grid>
                  {
                    (itemlist?.rate == 0) ?
                      <Grid item>
                        <Button onClick={() => setOpenFileUploadPopup(true)} variant="outlined" color="primary">
                        Select the artwork (pdf) that you wish to print
                        </Button>
                        <div>{selectedFile && `Selected file: ${selectedFile.name}`}</div>
                      </Grid> : ""
                  }
                  {
                    (itemlist.questionnaire?.length) ?
                      <Grid item xs={12}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>SrNo</TableCell>
                              <TableCell>Question</TableCell>
                              <TableCell>Answer</TableCell>

                              {/* <TableCell>Sumbit</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                              itemlist.questionnaire.map((questionObject, ind) => (
                                <TableRow key={ind}>
                                  <TableCell>{ind}</TableCell>
                                  <TableCell className={classes.Size}>{questionObject.question} { (questionObject.compulsory || questionObject.compulsoryWithPdf) && <span style={{'color':'red'}}>*</span>}</TableCell>
                                  <TableCell className={classes.Quantity}>
                                    {
                                      questionObject.probableAnswers ?
                                        <Select
                                          labelId="answer"
                                          id={ind}
                                          name='answer'
                                          label="answer"
                                          required
                                          onChange={(event)=> {handleSelectAnswer(event, ind)}}
                                        >
                                          {questionObject.answers.split('|')?.map((answer, ansIndex) => (
                                            <MenuItem key={ansIndex} value={answer}>
                                              {answer}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                        :
                                        <Controls.Input
                                          name="answer"
                                          id={ind}
                                          onChange={(event)=> {handleInputAnswer(event, ind)}}
                                          className={classes.Input}
                                        />
                                    }
                                  </TableCell>

                                </TableRow>
                              ))
                            }

                          </TableBody>
                        </Table>
                      </Grid> : ""
                  }
                  <Grid item xs={12}>
                    <TextField
                      label="CorefNo (Optional)"
                      type="text"
                      name="corefno"
                      InputLabelProps={{ shrink: true }}
                      value={corefno}
                      onChange={handleInputChange}
                      className={classes.section}
                    />
                    <Typography variant="body1" component="p" className={classes.section}>
                      Add your reference to identify the product when it reaches you eg. You po no
                    </Typography>
                    {/* </div> */}
                  </Grid>
                </Grid>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabs}
                >
                  <Tab label="Details" />
                  <Tab label="Reviews" />
                  <Tab label="Shipping" />
                </Tabs>
                <Box className={classes.tabContent}>
                  {tabIndex === 0 && <Typography>Product details content...</Typography>}
                  {tabIndex === 1 && <Typography>Product reviews content...</Typography>}
                  {tabIndex === 2 && <Typography>Shipping information content...</Typography>}

                  {
                    itemlist.moreinfo && (
                      <MenuItem onClick={() => { setOpenPopup(true) }}>click-here for more info !!</MenuItem>
                    )
                  }
                </Box>
              </Grid>
            </Grid>
            <Dialog open={openFileUploadPopup || selectedSizeAndQuantity.isOpen} onClose={() => {setOpenFileUploadPopup(false); setSelectSizeAndQuantity(selectedQuantityAndSizeDefaultValue);}}>
              <DialogTitle>{openFileUploadPopup ? 'select Additional Files OR Art-Work' : 'Select Listed Size and Quantity'}</DialogTitle>
              <DialogContent>
                {
                  openFileUploadPopup ?
                    <Input id="pdf-upload" type="file" multiple={false} inputProps={{ accept: 'application/pdf' }} onChange={(e) => setTempFile(e.target.files[0])} /> :
                    <>
                      <Select
                        fullWidth
                        value={selectedSizeAndQuantity.selectedSize}
                        onChange={handleSelectSize}
                        name="size"
                        displayEmpty
                        renderValue={(selected) => {
                          if (!selected || selected?.length === 0) {
                            return <em>Select Size</em>;
                          }
                          return selected;
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>Select Size</em>
                        </MenuItem>
                        {itemlist?.sizes?.map((size, index) => (
                          <MenuItem key={index} value={size}>
                            {size}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField
                        variant="outlined"
                        label="Quantity"
                        name="quantity"
                        type="number"
                        value={selectedSizeAndQuantity.quantity}
                        onChange={handleQuanntityForSelectedSize}
                        fullWidth
                        margin="normal"
                        error={Boolean(selectedSizeAndQuantity.error)}
                        helperText={selectedSizeAndQuantity.error}
                      />
                    </>
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={() => { setOpenFileUploadPopup(false); setSelectSizeAndQuantity({selectedQuantityAndSizeDefaultValue}); }} color="primary">
                  Cancel
                </Button>
                {
                  openFileUploadPopup ?
                    <Button
                      onClick={() => {
                        if (tempSelectedFile) {
                          setSelectedFile(tempSelectedFile);
                          setTempFile(undefined);
                          setNotify({
                            isOpen: true,
                            message: 'File selected successfully',
                            type: 'success'
                          });
                          setOpenFileUploadPopup(false);
                        }
                      }}
                      color="primary"
                      disabled={tempSelectedFile ? false : true}
                      startIcon={<CloudUploadIcon />}
                    >
                      select
                    </Button> :
                    <Button
                      onClick={() => {
                        console.log("click on add to cart");
                        if (selectedSizeAndQuantity.selectedSize && selectedSizeAndQuantity.quantity >= itemlist.linebrk) {
                          let sizeObject = {
                            selectedSize: selectedSizeAndQuantity.selectedSize,
                            quantity: selectedSizeAndQuantity.quantity
                          }
                          setSizeAndQuantity([sizeObject]);
                        }
                      }}
                      color="primary"
                      startIcon={<AddShoppingCartTwoToneIcon />}
                      disabled={!(selectedSizeAndQuantity.selectedSize && (Number(selectedSizeAndQuantity?.quantity) >= itemlist.linebrk))}
                    >
                      Add To Cart
                    </Button>
                }
              </DialogActions>
            </Dialog>
            <Dialog open={itemlist && openZeroValueInfoPopup} onClose={()=> {setOpenZeroValueInfoPopup(false)}}>
              <DialogTitle>Important Information</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <ol>
                    <li>The price will differ from generic products and shall be based on quantity, color, and material selection.</li>
                    <li>The minimum quantity that you can order is {`"${itemlist.linebrk}"`} pcs.</li>
                    <li>The correct prices will be given to you by correcting the zero-value proforma invoice.</li>
                    <li>The delivery period will also vary.</li>
                    <li>Please read the PDF before placing the order.</li>
                    <li>Please attach a high-resolution PDF to get a quote for your requirement (this is mandatory).</li>
                    <li>If you don’t have a high-resolution PDF, please provide a rough drawing in PDF format. Artwork will be created and sent to you for approval. The delivery time will be calculated from the date of artwork approval.</li>
                  </ol>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=> {setOpenZeroValueInfoPopup(false)}} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Popup
              title=""
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <ProductDetailsPdf docno={itemlist.docno} />
            </Popup>
            <Notification
              notify={notify}
              setNotify={setNotify}
            />
          </React.Fragment> : ''

      }
    </>

  );
};

export default ProductDetailPage;