import React from "react";
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfTwoTone';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 305,
    minWidth: 305,
    margin: theme.spacing(2), // Add some margin for spacing between cards
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add shadow for depth
    borderRadius: 8, // Add some border radius for rounded corners
    transition: 'transform 0.3s ease-in-out', // Add transition for hover effect
    '&:hover': {
      transform: 'scale(1.02)', // Scale up slightly on hover
    },
  },
  media: {
    height: 196,
  },
  content: {
    height: 120,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold', // Make title bold
    marginBottom: theme.spacing(1), // Add some bottom margin
    cursor: 'pointer', // Show pointer cursor on hover
    '&:hover': {
      color: theme.palette.primary.main, // Change text color on hover
    },
  },
  info: {
    fontSize: 14,
    marginBottom: theme.spacing(1), // Add some bottom margin
  },
  rate: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'bold', // Make rate bold
  },
  productPdfPopup: {
    color: theme.palette.secondary.main, // Use secondary color for more_info text
    cursor: 'pointer',
    marginLeft: theme.spacing(1), // Add left margin
    '&:hover': {
      textDecoration: 'underline', // Underline on hover
    },
  },
}));

const CollectionItem = ({ item, infletedRateApply, openProductDetailPdf }) => {
  const { docno, ordinst1, ordinst2, image, rate, inflatedRate, moreinfo } = item;
  const navigate = useNavigate();
  const classes = useStyles();

  const handleOnClick = () => {
    navigate(`/productDetail/${item.id}`);
  };

  return (
    <Card className={classes.root} onClick={handleOnClick}>
      <CardActionArea>
        {image ? (
          <CardMedia className={classes.media} image={`data:image/png;base64,${image}`} title={docno} />
        ) : (
          <CardMedia className={classes.media} title={docno} />
        )}
        <CardContent className={classes.content}>
          <Typography className={classes.title} gutterBottom>
            {docno}
          </Typography>
          <Typography className={classes.info} variant="body2" color="textSecondary" gutterBottom>
            {ordinst1}
          </Typography>
          <Typography className={classes.info} variant="body2" color="textSecondary" gutterBottom>
            {ordinst2}
          </Typography>
          <Typography className={classes.rate}>
            ₹{rate  ? (rate / 100) : 0}
            {moreinfo && (
              <div onClick={(e) => { e.stopPropagation(); openProductDetailPdf(docno); }}>
                <span className={classes.productPdfPopup} >
                  more_info
                </span>
                <PictureAsPdfTwoToneIcon/>
              </div>
            )}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CollectionItem;
