import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null
}

const userSlice = createSlice({

  name: "user",
  initialState,
  reducers: {
    addUser(state, action) {
      state.currentUser = action.payload;
      // console.log(action.payload)
    },
    removeUser(state) {
      state.currentUser = null;
      
    }
  },
});

console.log(userSlice.actions.addUser());

export default userSlice.reducer;
export const { addUser, removeUser } = userSlice.actions;
