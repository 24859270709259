import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import controls from '../controls/Controls';
import { Button, Grid } from '@mui/material';

const SuspectAcmast = (props) => {

  const {handleSubmitAccountCode}=props;
  const [Acmast, setSuspectAcmast]= useState([]);

  useEffect( () => {
    const config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    axios.get(`ACMASTDT`,config)
    .then(response => { 
      setSuspectAcmast(response.data);
    })
    .catch(error => {
      alert("Server Error ! 👎")
    });
    
    },[]);

    const hiddenInputAc_code = (event) =>{
      const { value, id } = event.target;
      let acmastTemp = [...Acmast];
      acmastTemp[Number(id)] = { ...acmastTemp[Number(id)], ['accode']: value, ['billtonm'] : value };
      setSuspectAcmast([...acmastTemp]);
        // }
        // setAc_Code({
        //   [name] : value,
        // });
    };

  const handleSubmit = () =>{
    let acCodeNotSumitted = Acmast.find(obj=> !obj?.accode);
    if(acCodeNotSumitted) {
      alert("please submit all ac_code");
    } else {
      let acmastTemp = [...Acmast];
      acmastTemp.forEach(obj => {
        const rootObjs = acmastTemp.filter(root => root.id === Number(obj.billto));
        rootObjs.forEach(rootObj => {
          obj.billto = rootObj.accode;
        });
      });
      setSuspectAcmast([...acmastTemp]);
      setTimeout(() => {
        handleSubmitAccountCode(Acmast,setSuspectAcmast); 
      }, 10);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>AC_CODE</TableCell>
                <TableCell>AC_NAME</TableCell>
                <TableCell>GRP_CODE</TableCell>
                <TableCell>PERSON</TableCell>
                {/* <TableCell align="right">Submit</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Acmast.map((val, ind) => (
                <TableRow key={ind}>
                  <TableCell>
                    <controls.Input
                      margin="normal"
                      name={val.usercd}
                      label="Ac_Code"
                      id={ind}
                      value={val.accode ? val.accode : ''}
                      onChange={hiddenInputAc_code}
                      inputProps={{
                        maxLength: 6,
                      }}
                    />
                  </TableCell>
                  <TableCell>{val.acname}</TableCell>
                  <TableCell>{val.grpcode}</TableCell>
                  <TableCell>{val.person}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop:'10px' }}>
          <Button disabled={!Acmast?.length} onClick={handleSubmit} variant="outlined">Submit Accounts</Button>
        </Grid>
      </Grid>
    </>
  );
}
  
  export default SuspectAcmast;