import axios from 'axios';
import React, { useEffect, useState } from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Collapse, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom/dist';
import * as InvoiceCreation from '../../components/InvoiceCreation/InvoiceCreation';
import { useTable } from '../../components/useTable';
import ShoppingDetailDailogTable from '../profile-component/User-Overlay/ShoppingDetailsTable';


  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    media: {
        maxWidth: 280,
        height: 196,
      },
    content:{
        height: 90,
        maxWidth: 280,
      },
    total:{
      backgroundColor: "#c0c0c0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
  });
  function Row(props) {
    const { row} = props;
    const [open, setOpen] = React.useState(false);
    
    
    const classes = useRowStyles();
    const handleClick =async()=>{
        setOpen(!open);
    }

    const getRounOffValue = (val)=>{
        let valArray = val.toString().split(".");
        if(valArray[1]){
          return valArray[0]+"."+valArray[1].substring(0,2);
        }else{
          return valArray[0];
        }
    }

    const handleDownloadProfoma=()=>{
        const deliveryAddress ={
          acname:row.acname,
          acaddr1:row.acaddr1,
          acaddr2:row.acaddr2,
          acaddr3:row.acaddr3,
          acaddr4:row.acaddr4,
          citynm:row.citynm,
          statenm:row.statenm,
          countrynm:row.countrynm,
          zip:row.zip
        };
      InvoiceCreation.profomaInvoiceCreation(row,deliveryAddress);
    }

    return (
      <React.Fragment>
        <TableRow className={classes.root}  onClick={() => handleClick()}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => handleClick()}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
          {row.orderitems.map((val,ind)=>(<p key={ind}>{val.docno} <br/></p>))}
          </TableCell>
          <TableCell align="right">{row.id}</TableCell>
          <TableCell align="right">{row.createdAt}</TableCell>
          {/* <TableCell align="right"></TableCell> */}
          <TableCell align="right">{row.subTotal}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                
                <Table size="small" aria-label="purchases">
                <TableBody>
               
                <TableRow>
               
                <TableCell><DescriptionIcon fontSize="large"/><Button color="primary" onClick={handleDownloadProfoma}>Download</Button> </TableCell>
                </TableRow>
               </TableBody>
               </Table>
              
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
    
  }

const InvoiceDownload =({row})=>{
  const navigate = useNavigate();
const classes = useRowStyles();
const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
const [orders,setOrders]=useState([]);

const pages = [10, 25];
const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
} = useTable(orders, pages, null, filterFn);

useEffect(()=>{
  
    // getDownloadInvoice();
       
    },[]);

const getDownloadInvoice = async() =>{
  const token= localStorage.getItem('token');
  if(token){
      let config ={
          headers:{ Authorization: 'Bearer '+token}
      }
      await axios.get('downloadInvoice',config).then(
          response=>{
              setOrders(response.data);
               console.log(response.data);
      })
      .catch(errors => {
          localStorage.clear();
          navigate("/");
      });
  }else{
      localStorage.clear();
      navigate("/");
  }
}

return (
<>
{/* <Card>
      <CardHeader color="primary">
    
      </CardHeader>
      <CardBody>
        <Paper>
          {
            orders.length ?
<>
            <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
            <TableCell />
            <TableCell>Labels</TableCell>
            <TableCell align="right">Order No</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Amount</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {recordsAfterPagingAndSorting().map((row,ind) => (
            <Row key={row.id} ind={ind} row={row} />
            ))}
            </TableBody>
            </Table>
        
        <TblPagination />
        </>:
        <Typography>"No orders currently" continue shopping--<NavLink to="/" className={classes.total}>click here</NavLink></Typography> 
        }
        </Paper>
      </CardBody>
</Card> */}
<ShoppingDetailDailogTable classsName={classes.root}  username={null} />
</>
);
}

export default InvoiceDownload;