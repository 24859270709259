import React, { useState, useEffect } from 'react';
import { Button, Grid, Input, InputLabel, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, CssBaseline, Divider } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { makeStyles } from '@mui/styles';
import Controls from '../controls/Controls';
import { Form } from '../useForm';
import axios from 'axios';
import Spinner from '../spinner/spinner.component';
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreIcon from '@mui/icons-material/Restore';

const initialFValues = {
    size: "",
    inflatedRate: "",
    sequence: ""
};

const initialQuestionValues = {
    question: "",
    compulsory: false,
    compulsoryWithPdf: false,
    probableAnswers: false,
    answers: "",
    status: ""
};

const useStyles = makeStyles((theme) => ({
    submitButton1: {
        paddingBottom: theme.spacing(2),
    },
    submitButton2: {
        paddingBottom: theme.spacing(1),
    },
    note: {
        paddingTop: theme.spacing(2),
        fontSize: 13,
        color: "#ff1744",
    },
    delete: {
        backgroundColor: '#ff9494'
    },
    waiting: {
        backgroundColor: '#ffffb2'
    }
}));

export default function ProductPopup(props) {
    const classes = useStyles();
    const { addOrEdit, insertInflatedRate, recordForEdit, handleSequenceChange, handleUpdateMoreInfo } = props;
    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [fileName, setFileName] = useState('');
    const [questions, setQuestions] = useState([]);
    const [quantityModification, setQuantityModification] = useState(false);
    const [questionValues, setQuestionValues] = useState(initialQuestionValues);
    const [questionErrors, setQuestionErrors] = useState({});

    useEffect(() => {
        if (recordForEdit) {
            const { sizes, inflatedRate, sequence } = recordForEdit;
            setValues((prevValues) => ({
                ...prevValues,
                size: sizes ? sizes.join('|') : "",
                inflatedRate: inflatedRate || "",
                sequence: sequence || "",
            }));
            fetchProductQuestionnaire();
        }
    }, [recordForEdit]);

    const fetchProductQuestionnaire = async () => {
        const config = {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        };
        const dataObj = {
            docno: recordForEdit.docno
        };

        try {
            const response = await axios.post("productsController/fetchProductResources", dataObj, config);
            if (response.data) {

                if(response.data?.quantityModification) {
                    setQuantityModification(response.data?.quantityModification)
                }

                if(response.data?.questionnaire) {
                    let data = JSON.parse(response.data?.questionnaire);
                    const tempResponse = data.map((obj) => ({
                        ...obj,
                        status: 'saved',
                    }));
                    setQuestions(tempResponse);
                }
            }
        } catch (error) {
            alert("Server Error ! 👎");
        }
    }

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ('size' in fieldValues) temp.size = fieldValues.size ? "" : "This field is required.";
        if ('inflatedRate' in fieldValues) temp.inflatedRate = !isNaN(fieldValues.inflatedRate) ? "" : "Rate is not valid.";

        setErrors(temp);
        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const validateQuestion = (fieldValues = questionValues) => {
        let temp = { ...questionErrors };
        if ('question' in fieldValues) temp.question = fieldValues.question ? "" : "This field is required.";
        if (fieldValues.probableAnswers && 'answers' in fieldValues) temp.answers = fieldValues.answers ? "" : "This field is required when probable answers are enabled.";

        setQuestionErrors(temp);
        if (fieldValues === questionValues) return Object.values(temp).every((x) => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            addOrEdit(values.size, resetForm);
        }
    };

    const handleSubmitInflatedRate = (e) => {
        e.preventDefault();
        if (!isNaN(values.inflatedRate)) {
            insertInflatedRate(values.inflatedRate, resetForm);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        validate({ [name]: value });
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleQuestionInputChange = (e) => {
        const { name, value } = e.target;
        validateQuestion({ [name]: value });
        setQuestionValues({
            ...questionValues,
            [name]: value
        });
    };

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target;
        if(name === 'quantityModification') {
            proceedWithUpdateRecord(checked);
        } else if(name === 'compulsoryWithPdf') {
            setQuestionValues({
                ...questionValues,
                [name]: checked,
                ['compulsory']: checked
            });
        } else {
            setQuestionValues({
                ...questionValues,
                [name]: checked
            });
        }
    };

    const handleAddQuestion = () => {
        if (validateQuestion()) {
            setQuestions([...questions, questionValues]);
            setQuestionValues(initialQuestionValues);
        }
    };

    const handleSubmitAddedQuestion = () => {
        if (questions?.length) {
            proceedWithUpdateRecord(undefined);
        }
    }

    const proceedWithUpdateRecord = async(quantityModificationChecked) => {
        const config = {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        };
        let questionnaireArray = (quantityModificationChecked != undefined) ? questions.filter(obj=> obj.status != '' && obj.status != 'delete') : questions.filter(obj=> obj.status != 'delete');
        const dataObj = {
            docno: recordForEdit.docno,
            questionnaire: questionnaireArray?.length ? JSON.stringify(questionnaireArray) : null ,
            quantityModification: quantityModificationChecked
        };

        try {
            const response = await axios.post("productsController/updateProductResources", dataObj, config);
            if (response.data) {
                if(quantityModificationChecked != undefined) {
                    setQuantityModification(quantityModificationChecked);
                    props.setNotify({
                        isOpen: true,
                        message: `Product Quentity Modification ${quantityModificationChecked ? '{Allowed} ' : '{Disabled} '}👍`,
                        type: 'success'
                    });
                } else {
                    props.setNotify({
                        isOpen: true,
                        message: 'update product questionnaire Successfully👍',
                        type: 'success'
                    });
                    const tempResponse = questions.filter(obj => obj.status != 'delete').map(obj => ({
                        ...obj,
                        status: 'saved',
                    }));
                    setQuestions(tempResponse);
                }
                
            }
        } catch (error) {
            alert("Server Error ! 👎");
        }
    }

    const deleteOrRestoreQuestion = (index, question) => {
        if (question.status == 'saved') {
            const tempResponse = [...questions];
            tempResponse[index].status = 'delete';
            setQuestions(tempResponse);
        } else if (question.status == 'delete') {
            const tempResponse = [...questions];
            tempResponse[index].status = 'saved';
            setQuestions(tempResponse);
        } else {
            if (questions?.length && questions[index]) {
                setQuestions(questions.filter((_, i) => i !== index));
            }
        }

    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({});
    };

    const handleSubmitSequence = (e) => {
        e.preventDefault();
        if (!isNaN(values.sequence)) {
            handleSequenceChange(values.sequence, resetForm);
        }
    };

    const handleUploadProductPdf = async (event) => {
        const { files } = event.target;
        setFileName(files[0].name);
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('orderId', 0);
        formData.append('docno', recordForEdit.docno);

        const config = {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": "multipart/form-data"
            }
        };

        setSpinnerActive(true);

        try {
            const response = await axios.post("productsController/uploadProductDetailsPdf", formData, config);
            setSpinnerActive(false);
            if (response.data) {
                alert(`${response.data} for ${recordForEdit.docno} !!!`);
                handleUpdateMoreInfo(recordForEdit, true);
            }
        } catch (error) {
            setSpinnerActive(false);
            alert("Server Error ! 👎");
        }
    };

    const handleSwitchProductMoreInfo = async (event) => {
        const config = {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        };
        const dataObj = {
            "docno": recordForEdit?.docno,
            "moreinfo": event.target.checked
        };

        try {
            const response = await axios.post("productsController/updateProductMoreInfoFlag", dataObj, config);
            if (response.data) {
                alert(`Updated product more_information flag for ${recordForEdit.docno} !!!`);
                handleUpdateMoreInfo(recordForEdit, dataObj.moreinfo);
            }
        } catch (error) {
            alert("Server Error ! 👎");
        }
    };

    return (
        <Form>
            {spinnerActive && <Spinner />}

            <Grid container spacing={2}>
                {recordForEdit && recordForEdit.ordertype.toLowerCase() === "with additional sheet" && (
                    <>
                        <Grid item xs={12}>
                            <InputLabel className={classes.note}>
                                Please note: you need to add size one by one and separate them with | before submitting.
                            </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                            <Controls.Input
                                name="size"
                                label="Size"
                                value={values.size}
                                onChange={handleInputChange}
                                error={errors.size}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controls.Button
                                type="submit"
                                text="Submit"
                                onClick={handleSubmit}
                            />
                        </Grid>
                        <Divider sx={{ width:'100%', my: 2, borderColor: 'primary.main', borderWidth: '2px' }} />
                    </>
                )}
                <Grid item xs={6}>
                    <Controls.Input
                        name="inflatedRate"
                        label="Inflated Rate"
                        value={values.inflatedRate}
                        onChange={handleInputChange}
                        error={errors.inflatedRate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Button
                        type="submit"
                        text="Submit"
                        onClick={handleSubmitInflatedRate}
                    />
                </Grid>
                <Divider sx={{ width:'100%', my: 2, borderColor: 'primary.main', borderWidth: '2px' }} />
                <Grid item xs={6}>
                    <Controls.Input
                        name="sequence"
                        label="Sequence"
                        value={values.sequence}
                        onChange={handleInputChange}
                        error={errors.sequence}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.Button
                        type="submit"
                        text="Submit"
                        onClick={handleSubmitSequence}
                    />
                </Grid>
                <Divider sx={{ width:'100%', my: 2, borderColor: 'primary.main', borderWidth: '2px' }} />
                <Grid item xs={6}>
                    More Info (PDF)
                    <Switch
                        id={recordForEdit?.id}
                        checked={recordForEdit?.moreinfo || false}
                        onChange={handleSwitchProductMoreInfo}
                        color="primary"
                    />
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <Input
                            id="pdf-upload"
                            type="file"
                            inputProps={{ accept: 'application/pdf' }}
                            onChange={handleUploadProductPdf}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="pdf-upload">
                            <Button
                                variant="contained"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload PDF
                            </Button>
                        </label>
                        {fileName && <div>Selected file: {fileName}</div>}
                    </div>
                </Grid>
                <Divider sx={{ width:'100%', my: 2, borderColor: 'primary.main', borderWidth: '2px' }} />
                {/* Questions Section */}
                
                <Grid item xs={12}>
                    <h3>{`Product Quantity Control (x > linebreak)`}</h3>
                </Grid>
                <Grid item xs={12}>
                {`Allow Quantity Control (x > linebreak) EX: 1200 > 1000`}
                    <Switch
                        name="quantityModification"
                        checked={quantityModification}
                        onChange={handleSwitchChange}
                        color="primary"
                    />
                </Grid>
                <Divider sx={{ width:'100%', my: 2, borderColor: 'primary.main', borderWidth: '2px' }} />
                <Grid item xs={12}>
                    <h3>{`Add Product Related Questions`}</h3>
                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        name="question"
                        label="Question"
                        value={questionValues.question}
                        onChange={handleQuestionInputChange}
                        error={questionErrors.question}
                    />
                </Grid>
                <Grid item xs={3}>
                Compulsory Without PDF
                    <Switch
                        name="compulsory"
                        checked={questionValues.compulsory}
                        onChange={handleSwitchChange}
                        color="primary"
                    />
                </Grid>
                <Grid item xs={3}>
                    Compulsory with PDF
                    <Switch
                        name="compulsoryWithPdf"
                        checked={questionValues.compulsoryWithPdf}
                        onChange={handleSwitchChange}
                        color="primary"
                    />
                </Grid>
                <Grid item xs={6}>
                    Probable Answers
                    <Switch
                        name="probableAnswers"
                        checked={questionValues.probableAnswers}
                        onChange={handleSwitchChange}
                        color="primary"
                    />
                </Grid>
                {questionValues.probableAnswers && (
                    <Grid item xs={6}>
                        ex: ans1|ans2|ans3...n
                        <Controls.Input
                            name="answers"
                            label="Answers"
                            value={questionValues.answers}
                            onChange={handleQuestionInputChange}
                            error={questionErrors.answers}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Controls.Button
                        text="Add Question"
                        onClick={handleAddQuestion}
                        disabled={!questionValues.question || (questionValues.probableAnswers && !questionValues.answers)}
                    />
                </Grid>

                {/* Questions List */}
                <Grid item xs={12}>
                    <h3>Added Questions</h3>
                    {questions.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Question</TableCell>
                                        <TableCell>Compulsory Without PDF</TableCell>
                                        <TableCell>Compulsory With PDF</TableCell>
                                        <TableCell>Probable Answers</TableCell>
                                        <TableCell>Answers</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {questions.map((question, index) => (
                                        <TableRow className={(question.status == 'delete') ? classes.delete : (question.status == '') ? classes.waiting : ''} key={index}>
                                            <TableCell>{question.question}</TableCell>
                                            <TableCell>{question.compulsory ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{question.compulsoryWithPdf ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{question.probableAnswers ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{question.answers || 'N/A'}</TableCell>
                                            <TableCell>
                                                <IconButton color='error' onClick={() => { deleteOrRestoreQuestion(index, question) }} aria-label="delete">
                                                    {question.status != 'delete' ? <Tooltip title="Delete this question !"> <DeleteIcon /> </Tooltip> : <Tooltip title="Restore this question !">  <RestoreIcon /> </Tooltip>}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <p>No questions added yet.</p>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Controls.Button
                        text="Submit Added/Modified Questions"
                        onClick={handleSubmitAddedQuestion}
                        disabled={ !questions?.some(obj=> obj.status == 'delete' || obj.status == '') }
                    />
                </Grid>
            </Grid>
        </Form>
    );
}
