import React, { useState } from "react";
import axios from 'axios';
import Notification from "../Notification";
import _ from 'lodash';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  note: {
    paddingTop :theme.spacing(2),
    fontSize: 13,
    color: "#ff1744",
},
}));

const ProductsInfo = () => {

  const [selectedFile, setData] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })
  const [isFlag, setIsFlag] = useState(true);
  const[acmastError,setAcmastError]=useState([]);
   const classes=useStyles();

  const InputEvent = (event) => {
    const file = event.target.files[0];

    setData(file);
  };
    
    const ProductsSubmit = async(e) => {
        e.preventDefault();
        const config ={
          headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const data = new FormData()
        data.append('file',selectedFile)
     
        await axios.post("productsController/productsUpload",data,config)
        .then(res => { 
          if(res) {
            setNotify({
              isOpen: true,
              message: 'File Uploaded Successfully 👍',
              type: 'success'
            })
          } else {
            setNotify({
              isOpen: true,
              message: 'File upload not done, please try again 👎',
              type: 'error'
            })
           
          }
          
        })
        .catch(error => {
          if(error?.response?.data) {
            setNotify({
              isOpen: true,
              message: `${error?.response?.data} 👎`,
              type: 'error'
            });
          } else {
            setNotify({
              isOpen: true,
              message: 'File upload not done, please try again 👎',
              type: 'error'
            });
          }
        });
      };
      
      const ACMASTSubmit = async(e) => {
        e.preventDefault();
        const config ={
          headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const data = new FormData()
        data.append('file',selectedFile)
        await axios.post("acMastController/ACMASTUpload",data,config)
        .then(res => { // then print response status
          if(res.data===true){
                 setNotify({
              isOpen: true,
              message: `File Uploaded Successfully ${selectedFile.name}👍`,
              type: 'success'
            })
           

          }else{
                setNotify({
              isOpen: true,
              message: 'File upload not done, please try again 👎',
              type: 'error'
            })
          }
          
        })
        .catch(error => {
          if(error?.response?.data) {
            setNotify({
              isOpen: true,
              message: `${error?.response?.data} 👎`,
              type: 'error'
            });
          } else {
            setNotify({
              isOpen: true,
              message: 'File upload not done, please try again 👎',
              type: 'error'
            });
          }
        });
      };
      
      const CountrySubmit = async(e) => {
        e.preventDefault();
        const config ={
          headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const data = new FormData()
        data.append('file',selectedFile)
        await axios.post("countryUpload",data,config)
        .then(res => { // then print response status
          if(res.data===true){
            setNotify({
              isOpen: true,
              message: 'File Uploaded Successfully 👍',
              type: 'success'
            })
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'File upload not done, please try again 👎',
            type: 'error'
          })
        });
      };

      const StateSubmit = async(e) => {
        e.preventDefault();
        const config ={
          headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const data = new FormData()
        data.append('file',selectedFile)
        await axios.post("stateUpload",data,config)
        .then(res => { // then print response status
          if(res.data===true){
            setNotify({
              isOpen: true,
              message: 'File Uploaded Successfully 👍',
              type: 'success'
            })
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'File upload not done, please try again 👎',
            type: 'error'
          })
          });
      };
      
      const CitySubmit = async(e) => {
        e.preventDefault();
        const config ={
          headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const data = new FormData()
        data.append('file',selectedFile)
        await axios.post("cityUpload",data,config)
        .then(res => { // then print response status
          if(res.data===true){
            setNotify({
              isOpen: true,
              message: 'File Uploaded Successfully 👍',
              type: 'success'
            })
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'File upload not done, please try again 👎',
            type: 'error'
          })
          });
      };

      const taxSubmit= async(e)=>{
        e.preventDefault();
        const config ={
          headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const data = new FormData()
        data.append('file',selectedFile)
        await axios.post("taxMasterUpload",data,config)
        .then(res => { // then print response status
          if(res.data===true){
            setNotify({
              isOpen: true,
              message: 'File Uploaded Successfully 👍',
              type: 'success'
            })
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'File upload not done, please try again 👎',
            type: 'error'
          })
          });
      }

      const tapSubmit= async(e)=>{
        e.preventDefault();
        const config ={
          headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        const data = new FormData()
        data.append('file',selectedFile)
        await axios.post("tapMasterUpload",data,config)
        .then(res => { // then print response status
          if(res.data===true){
            setNotify({
              isOpen: true,
              message: 'File Uploaded Successfully 👍',
              type: 'success'
            })
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'File upload not done, please try again 👎',
            type: 'error'
          })
          });
      }
      
    return(
        <>
        <div className="container-fluid d-flex align-items-center pt-5 ">
            <div className="row">
            <div className="col-md-12 pt-3">
                <h2>FileUpload</h2>         
            {
            (isFlag==false) ?
              <div className="alert alert-danger alert-dismissible fade show" role="alert" >
                <button type="button" className="close" data-dismiss="alert" aria-label="close" >
                  <span aria-hidden="true" >&times;</span>
                </button>
                {
                  (!acmastError.length)?
                  <>
                  {acmastError.map((val, ind) =>
                    (
                    <p>{val}</p>
                    ))}
                    <br /> — <strong>check it out!</strong>
                  </>:<p>File upload not done, please try again !👎</p>
                }
              </div>
              :null
            }  
            <form onSubmit={ProductsSubmit}>
            <div className="form-group pb-2">
                <label htmlFor="fileupload">Products Upload(EXCEL)</label>
                <input type="file" className="form-control" id="file" name="file" onChange={InputEvent} required/>
            </div>
            <div className="pb-4">
            <button type="submit" className="btn btn-primary" >Submit</button>
            </div>
            </form>

            <form onSubmit={ACMASTSubmit}>
            <div className="form-group pb-2">
                <label htmlFor="fileupload">ACMAST Upload(EXCEL)</label>
                <input type="file" className="form-control" id="file" name="file" onChange={InputEvent} required/>
            </div>
            <div className="pb-4">
            <button type="submit" className="btn btn-primary" >Submit</button> 
           
            </div>
            
         
            </form>

            <form onSubmit={CountrySubmit}>
            <div className="form-group pb-2">
                <label htmlFor="fileupload">Country Upload(EXCEL)</label>
                <input type="file" className="form-control" id="file" name="file" onChange={InputEvent} required/>
            </div>
            <div className="pb-4">
            <button type="submit" className="btn btn-primary" >Submit</button>
            </div>
            </form>

            <form onSubmit={StateSubmit}>
            <div className="form-group pb-2">
                <label htmlFor="fileupload">State Upload(EXCEL)</label>
                <input type="file" className="form-control" id="file" name="file" onChange={InputEvent} required/>
            </div >
            <div className="pb-4">
            <button type="submit" className="btn btn-primary" >Submit</button>
            </div>
            </form>

            <form onSubmit={CitySubmit}>
            <div className="form-group pb-2">
                <label htmlFor="fileupload">City Upload(EXCEL)</label>
                <input type="file" className="form-control" id="file" name="file" onChange={InputEvent} required/>
            </div >
            <div className="pb-4">
            <button type="submit" className="btn btn-primary" >Submit</button>
            </div>
            </form>

            <form onSubmit={taxSubmit}>
            <div className="form-group pb-2">
                <label htmlFor="fileupload">Tax Master Upload(EXCEL)</label>
                <input type="file" className="form-control" id="file" name="file" onChange={InputEvent} required/>
            </div >
            <div className="pb-4">
            <button type="submit" className="btn btn-primary" >Submit</button>
            </div>
            </form>

            <form onSubmit={tapSubmit}>
            <div className="form-group pb-2">
                <label htmlFor="fileupload">Tap Master Upload(EXCEL)</label>
                <input type="file" className="form-control" id="file" name="file" onChange={InputEvent} required/>
            </div >
            <div className="pb-4">
            <button type="submit" className="btn btn-primary" >Submit</button>
            </div>
            </form>
            
           
            </div>
            </div>
        </div>
        <Notification
                notify={notify}
                setNotify={setNotify}
        />
        </>
    );
};
export default ProductsInfo;