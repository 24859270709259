import React,{useState} from "react";
import axios from 'axios';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';
import { Button} from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Notification from "../Notification";
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
const useStyles = makeStyles((theme) => ({
    root:{
        paddingTop:"30px"
    }
  }));

const OrderPage=(props)=>{
    const [notify, setNotify] =useState({ isOpen: false, message: '', type: '' });
    const classes=useStyles();
    const handleClick =async(e)=>{
    const config ={
        headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
      }
      await axios.get("downloadOrders", config)
        .then(res => { // then print response status
          if (res.data) {
            setNotify({
              isOpen: true,
              message: 'Downloaded Successfully check location "...\ONLINEOR\ORDER\ORDER.csv" 👍',
              type: 'success'
            });
            axios.get("manageOrderController/getDownloadedOrder").then(response => {
              if (response.data) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ORDER.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
              }
            });
          } else {
            setNotify({
                isOpen: true,
                message: 'download not successfull 👎',
                type: 'error'
            })
        }
      })
      .catch(error => {
        setNotify({
            isOpen: true,
            message: 'download un-successful 👎',
            type: 'error'
        })
      });
}

return(
<>
<Card className={classes.root}>
    
      <CardBody>
        <Button 
        variant="contained"
        color="primary"
        startIcon={<CloudUploadIcon />} 
        onClick={handleClick}
        >
            Download orders
        </Button> 
      </CardBody>   
</Card>
<Notification 
notify={notify}
setNotify={setNotify}/>
</>
);
}

export default (OrderPage);