import { Container, Grid } from "@mui/material";
import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";

const StyledImageTransition = styled.div`
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;

  .slide-img {
    height: 200px;
    width: 250px;
  }

  @media (max-width: 991px) {
    .rec-pagination {
      display: none !important;
    }
  }

  @media (max-width: 768px) {
    .rec-pagination {
      display: none !important;
    }
  }
`;

const ImageTransition = () => {
  const images = Array.from({ length: 94 }, (_, i) => `Slide${i + 1}`);

  return (
      <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
          <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                  <>
                      <h1 style={{ textAlign: "center" }}>Vasants Labels Products</h1>
                      <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
                          {images.map((slide, index) => (
                              <div key={index}>
                                  <img src={()=>{return `../../images/VasantsLabelsSlides/${slide}.JPG`}} />
                                  <p className="legend">Legend 1</p>
                              </div>
                          ))}
                      </Carousel>
                  </>
              </Grid>
          </Grid>
      </Container>

  );
}

export default ImageTransition;
