import React,{useState} from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PublishIcon from '@mui/icons-material/Publish';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
// import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { red } from '@mui/material/colors';
import ConfirmDialog from "../ConfirmDialog";
import { useDispatch } from 'react-redux';
import { addUser } from '../../store/slices/user/UserSlice';
import { useNavigate } from 'react-router-dom/dist';
import { ListItemButton } from '@mui/material';


export function ListItems() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const {history,setCurrentUser}=props;
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

   const onClickHandler =(event)=>{
    const {id} = event.currentTarget;
    navigate(`/adDashboard/${id}`);
   } 
   const onClickLogoutButton =()=> {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })
    dispatch(addUser(null));
    localStorage.clear();
    navigate("/");
    }

   const mainListItems = (
    <div>
      <ListItemButton id="dashboard" onClick={onClickHandler}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton id="upload" onClick={onClickHandler}>
        <ListItemIcon>
          <PublishIcon />
        </ListItemIcon>
        <ListItemText primary="File Upload" />
      </ListItemButton>
      <ListItemButton id="orders" onClick={onClickHandler}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItemButton>
      <ListItemButton id="customers" onClick={onClickHandler}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Manage Customers" />
      </ListItemButton>
      <ListItemButton id="invoicedownload" onClick={onClickHandler}>
        <ListItemIcon>
          <PublishIcon/>
        </ListItemIcon>
        <ListItemText primary="Invoice Download" />
      </ListItemButton>
      <ListItemButton id="courier" onClick={onClickHandler}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Manage Courier" />
      </ListItemButton>
      {/* <ListItemButton>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Integrations" />
      </ListItemButton> */}
    </div>
  );
  
   const secondaryListItems = (
    <div>
      <ListSubheader inset>Saved reports</ListSubheader>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Current month" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Last quarter" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Year-end sale" />
      </ListItemButton>
      <ListItemButton onClick={() => {
        setConfirmDialog({
          isOpen: true,
          title: 'Confirm LogOut',
          subTitle: "Are you sure you want to LogOut?",
          onConfirm: () => { 
            onClickLogoutButton() 
          }
        })
      }}
      >
        <ListItemIcon>
          <SettingsPowerIcon style={{ color: red[500] }} />
        </ListItemIcon>
        <ListItemText primary="LogOut" />
      </ListItemButton>
      <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
  
  
  return {
    mainListItems,
    secondaryListItems,
  };

}
