import Axios from "axios";

export const addItemToCart = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToAdd.id  
  );
  if (existingCartItem) {
    if(existingCartItem.ordertype.toLowerCase=="without additional sheet"){
    return cartItems;
    // .map(cartItem =>
    //   cartItem.id === cartItemToAdd.id
    //     ? { ...cartItem, quantity: cartItem.quantity + cartItem.linebrk}
    //     : cartItem
    // );
    } else {
      if(existingCartItem.selectedSize !== cartItemToAdd.selectedSize) {
        if(cartItemToAdd.quantity) {
          return [...cartItems, { ...cartItemToAdd, quantity: cartItemToAdd.quantity }];
        } else {
          return [...cartItems, { ...cartItemToAdd, quantity: cartItemToAdd.linebrk }];
        }
      } else {
        return cartItems;
        // return [...cartItems, { ...cartItemToAdd, quantity: cartItemToAdd.linebrk }];
      }
    }
  }
if(cartItemToAdd.quantity) {
  return [...cartItems, { ...cartItemToAdd, quantity: cartItemToAdd.quantity }];
} else {
  return [...cartItems, { ...cartItemToAdd, quantity: cartItemToAdd.linebrk }];
}
  
};

export const fetchCartDetails = async(cartItems) => {
  const config = {
    headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  await Axios.get(`endUser/cartDetails`,config)
    .then(response => {
      if(response && response.data) {
        return [...response.data]
      } else {
        return [];
      }
    }).catch(e=> {
      console.log(e);
      return [];
    });
}

export const addItemToCartMultiplyQuantity = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToAdd.id
  );

  if (existingCartItem) {
    if(existingCartItem.ordertype.toLowerCase=="without additional sheet"){
    return cartItems.map(cartItem =>
      cartItem.id === cartItemToAdd.id
        ? { ...cartItem, quantity: cartItem.linebrk * cartItemToAdd.multiplyQTY}
        : cartItem
    );
    }else{
      return cartItems.map(cartItem =>
        (cartItem.id === cartItemToAdd.id && cartItem.selectedSize === cartItemToAdd.selectedSize)
        ? { ...cartItem, quantity: cartItem.linebrk * cartItemToAdd.multiplyQTY}
        : cartItem
      );
    }
  }

  return [...cartItems, { ...cartItemToAdd, quantity: cartItemToAdd.linebrk }];
};

export const addItemToCartUpdateQuantity = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToAdd.id
  );

  if (existingCartItem) {
    if(existingCartItem.ordertype.toLowerCase=="without additional sheet"){
    return cartItems.map(cartItem =>
      cartItem.id === cartItemToAdd.id
        ? { ...cartItem, quantity: cartItemToAdd.updatedQuantity}
        : cartItem
    );
    }else{
      return cartItems.map(cartItem =>
        (cartItem.id === cartItemToAdd.id && cartItem.selectedSize === cartItemToAdd.selectedSize)
        ? { ...cartItem, quantity: cartItemToAdd.updatedQuantity}
        : cartItem
      );
    }
  }

  return [...cartItems, { ...cartItemToAdd, quantity: cartItemToAdd.linebrk }];
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    cartItem => cartItem.id === cartItemToRemove.id
  );

  if (existingCartItem.quantity === existingCartItem.linebrk) {
    return cartItems.filter(cartItem => cartItem.id !== cartItemToRemove.id);
  }

  return cartItems.map(cartItem =>
    cartItem.id === cartItemToRemove.id
      ? { ...cartItem, quantity: cartItem.quantity - cartItem.linebrk }
      : cartItem
  );
};

export const calculateAndgetCartDetails = (cartItems, cartItemToRemove) => { 
  let index = cartItems.findIndex(cartItem => cartItem.id == cartItemToRemove.id && cartItem.selectedSize == cartItemToRemove.selectedSize);
  if(index != -1 && index != undefined) {
     cartItems.splice(index, 1);
     return [...cartItems];
  } else {
    return [...cartItems];
  }
}

