import React, { useState,useEffect } from "react";
import { Document, Page,pdfjs } from "react-pdf";
import axios from "axios";

export default function ProductDetailsPdf({docno}) {
  const [numPages, setNumPages] = useState(null);
  useEffect(() => { 
    pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    fetchPdfFile();
},[]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [showPdf , setShowPdf] = useState(undefined);
  const [pdfFileUrl, setPdfFile] = useState();

  const fetchPdfFile =()=> {
    if(docno) {
      const token= localStorage.getItem('token');
      const config ={
        headers: token ? { Authorization: 'Bearer '+token} : undefined,
        responseType: 'arraybuffer'
      }
        axios.post("productsController/getProductDetailPdf",{"docno": docno},config).then(response => {
            if (response.data) {
              let url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
              setPdfFile(url);
              setShowPdf(true);
            }
          }).catch(e=> {
            setShowPdf(false);
          });
    }
  } 

    return (
        <>
        {
          (showPdf === false) ? "No Data Found !!!" : (showPdf === undefined) ? "Checking Details !!!": ""
        }
            {
                pdfFileUrl ?
                    <Document
                    file={pdfFileUrl}
                        options={{ workerSrc: "/pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document> 
                    : null
            }

        </>
  );
}