import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  FormControl,
  MenuItem,
  Paper,
  Select
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import aboutUsPdf from "../../assets/pdf/about_us.pdf";
import contactUsPdf from "../../assets/pdf/contact_us.pdf";
import privacyStatementPdf from "../../assets/pdf/privacy_statement.pdf";
import refundPolicyPdf from "../../assets/pdf/refund_policy.pdf";
import shippingPolicyPdf from "../../assets/pdf/shipping_policy.pdf";
import termsOfServicePdf from "../../assets/pdf/terms_of_service.pdf";

const pdfFiles = [
  { id: 1, label: "About Us", file: aboutUsPdf },
  { id: 2, label: "Contact Us", file: contactUsPdf },
  { id: 3, label: "Privacy Statement", file: privacyStatementPdf },
  { id: 4, label: "Refund Policy", file: refundPolicyPdf },
  { id: 5, label: "Shipping Policy", file: shippingPolicyPdf },
  { id: 6, label: "Terms of Service", file: termsOfServicePdf },
];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800,
    margin: "auto",
    padding: theme.spacing(2),
  },
  select: {
    marginBottom: theme.spacing(2),
  },
  documentContainer: {
    border: "1px solid #ccc",
    padding: theme.spacing(2),
  },
  circularProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
}));

const PdfViewer = () => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPdf, setSelectedPdf] = useState(pdfFiles[0]);
  const [loading, setLoading] = useState(true);

  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const handlePdfChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const selectedPdf = pdfFiles.find((pdf) => pdf.id === selectedId);
    setSelectedPdf(selectedPdf);
    setPageNumber(1); // Reset page number when changing PDF
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageChange = ({ pageNumber }) => {
    setPageNumber(pageNumber);
  };

  return (
    <Paper className={classes.root}>
      <FormControl fullWidth className={classes.select}>
        <Select
          id="pdf-select"
          value={selectedPdf.id}
          onChange={handlePdfChange}
        >
          {pdfFiles.map((pdf) => (
            <MenuItem key={pdf.id} value={pdf.id}>
              {pdf.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.documentContainer}>
        <Document
          file={selectedPdf.file}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            onLoadSuccess={onPageChange}
            className={classes.page}
          />
        </Document>
      </div>
    </Paper>
  );
};

export default PdfViewer;
