import react,{useState} from "react";
import axios from "axios";

// const KEYS ={
//     employees:'employees',
//     employeeId:'employeeId'
// }

// export const getDepartmentCollection = ()=>([
//     { id: '1', title: 'Development' },
//     { id: '2', title: 'Marketing' },
//     { id: '3', title: 'Accounting' },
//     { id: '4', title: 'HR' },
// ])

export const getCourierToBeArranged = ()=>([
    { id: 'Y', title: 'Yes' },
    { id: 'N', title: 'No' },
    
])

export const getCouriers = ()=>([
    { id: 'F001', title: 'fedex' },
    { id: 'D001', title: 'dhl' },
    { id: 'U001', title: 'ups' },   
])

// export const getBrands = async()=>{
//     const config ={
//         headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
//     }
//     await axios.get('getBrands',config).then(
//         response => {
//             return response.data;
//         }
//       )
//       .catch(error => {
//        return [];
//       });
// }
export const getBrands = ()=>([
    { id: '1', title: 'pepe' },
    { id: '2', title: 'modo rapido' },
    { id: '3', title: 'arraow' },
    { id: '4', title: 'baby hug' },
    { id: '5', title: 'Arrow Sport' },
    { id: '6', title: 'reymond' },
    { id: '7', title: 'levies' },
    { id: '8', title: 'generic' },  
])

export const getCountry = ()=>([
    { id: '1', title: 'india' },
    { id: '2', title: 'china' },
    { id: '3', title: 'russia' },
    { id: '4', title: 'hong kong' },
    { id: '5', title: 'singapor' },
    { id: '6', title: 'america' },
    { id: '7', title: 'japan' },
    { id: '8', title: 'pakistan' },
    { id: '9', title: 'bhutan' },
    { id: '10', title: 'afganistan' },
    { id: '11', title: 'germany' },
    { id: '12', title: 'austrelia' },
    { id: '13', title: 'finland' },
])

export const getState = ()=>([
 { id: '1225', title: 'Andaman and Nicobar Islands' },
 { id: '2sd', title: 'Andhra Pradesh' },
 { id: '3455', title: 'Arunachal Pradesh' },
 { id: '4', title: 'Assam' },
 { id: '5855', title: 'Bihar' },
 { id: '6', title: 'Chandigarh' },
 { id: '74596', title: 'Chhattisgarh' },
 { id: '821', title: 'Dadra and Nagar Haveli' },
 { id: '9', title: 'Daman and Diu' },
 { id: '10', title: 'Delhi' },
 { id: '1sd1', title: 'Goa' },
 { id: '12', title: 'Gujarat' },
 { id: '13', title: 'Haryana' },
 { id: '14', title: 'Himachal Pradesh' },
 { id: '15', title: 'Jammu and Kashmir' },
 { id: '16', title: 'Jharkhand' },
 { id: '17', title: 'Karnataka' },
 { id: '18', title: 'Kerala' },
 { id: '19', title: 'Lakshadweep' },
 { id: '20', title: 'Madhya Pradesh' },
 { id: '21', title: 'Maharashtra' },
 { id: '22', title: 'Manipur' },
 { id: '23', title: 'Meghalaya' },
 { id: '24', title: 'Mizoram' },
 { id: '25', title: 'Nagaland' },
 { id: '26', title: 'Odisha' },
 { id: '27', title: 'Punjab' },
 { id: '28', title: 'Rajasthan' },
 { id: '29', title: 'Sikkim' },
 { id: '30', title: 'Tamil Nadu' },
 { id: '31', title: 'Telangana' },
 { id: '32', title: 'Tripura' },
 { id: '33', title: 'Uttar Pradesh' },
 { id: '34', title: 'Uttarakhand' },
 { id: '35', title: 'West Bengal' },
])

export const insertBrandBuyer = async(values,data) => {
    // export const [isSubmit, setSubmit] = useState(false);
    // const tempUsername=localStorage.getItem('tempUsername');
    // console.log(data);
    const logindata={
        ...values,
        ...data,
    }
    console.log(logindata);
    // const config ={
    //     headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    // }
    // console.log(logindata);

    // await axios.post('BrandBuyerForm',logindata,config).then(
    //     response => {
    //         console.log(response);
    //         localStorage.setItem('username',tempUsername);
    //         console.log(response.data);
    //         // localStorage.setItem('tempUsername',null);
    //     }
    //   )
    //   .catch(error => {
    //     console.log(error);
    //   });


    // let employees=getAllEmployees();
    // data['id'] = generateEmployeeId()
    // employees.push(data)
    // localStorage.setItem(KEYS.employees,JSON.stringify(employees))
}

export const insertVendersFactoryUsercase1 = async(values,data,usersCount,setCount,randomNumber,Userslist,setUserslist,setUserData,resetUserData,navigate) => {

    // console.log(data);
    // console.log(`its From insertVendrerUser ${randomNumber}`);
    
    const config ={
        headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    const logindata={
        ...values,
        ...data,
        suspendNo:randomNumber
    }
    console.log(logindata);
    await axios.post('ACMASTDTSuspendedForm',logindata,config).then(
        response => {
            console.log(response);
            console.log(response.data);
            if(response.data){
                alert(`${usersCount} Factory Vender is Created ...👍`);
                setCount(usersCount + 1);
                setUserslist({ userslist:[...Userslist.userslist,data] });
                resetUserData();
            }else{
                let user_cd='user_cd';
                alert(`Login Id Allready Used plese Use Different Login Id ...⛔`);
                setUserData({ ...data,[user_cd]:''})
            }
            // localStorage.setItem('tempUsername',null);
        }
      )
      .catch(error => {
        alert(`Your Session Is Timeout Please Login ...⛔`);
        navigate({
            pathname: '/signin/brandBuyer'
        });
      });
}
export const insertVendersFactoryUsercase2 = async(data,randomNumber,usersCount,setCount) => {

    // console.log(data);
    // console.log(`its From insertVendrerUser ${randomNumber}`);
    
    const config ={
        headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    const logindata={
        ...data,
        suspendNo: randomNumber
    }
    // console.log(logindata);
    await axios.post('registerSuspendedUser',logindata,config).then(
        response => {
            console.log(response);
            console.log(response.data);
            if(response.data==true){
                alert(`${usersCount} Factory Vender is Created ...👍`);
                setCount(usersCount + 1);
            }
            // localStorage.setItem('tempUsername',null);
        }
      )
      .catch(error => {
      });
}


export const deleteProduct= async(id)=> {
    const config ={
        headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    await axios.delete(`deleteProduct/${id}`,config).then(
        response => {}
    )
    .catch(error => {
    });
}

export const deleteBrand= async(id)=> {
    const config ={
        headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    await axios.delete(`deleteBrand/${id}`,config).then(
        response => {}
    )
    .catch(error => {
    });
}

export const deleteUser= async(id)=> {
    const config ={
        headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    await axios.delete(`deleteUser/${id}`,config).then(
        response => {}
    )
    .catch(error => {
    });
}

export const getRounOffValue = (val)=>{
    if(val) {
        let valArray = val.toString().split(".");
        if(valArray[1]){
          return valArray[0]+"."+valArray[1].substring(0,4);
        }else{
          return valArray[0];
        }
    } else {
        return val;
    }
    
}


// export const getAllBrands =async()=>{
//     const config ={
//         headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
//     }
//     await axios.get("All",config).then(
//         response => {
//             return response.data;
//         }
//     )
//     .catch(error => {
//         alert("Server Error ! 👎");
//         return [];
//     });
// }

// export const deleteBrand =()=>{
// }
// export const getAllProducts = async()=> {
//     const config ={
//         headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
//     }
//     await axios.post('products',config).then(
//         response => {
//             return response.data;
//         }
//     )
//       .catch(error => {
//     });
// }

// export const getAllSuspectAccounts = async()=> {
// const config ={
//     headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
//   }
//   await axios.get(`ACMASTDT`,config)
//   .then(response => { 
//       return response.data;
//   })
//   .catch(error => {
//     alert("Server Error ! 👎")
//   });
// }
// export function generateEmployeeId() {
//     if (localStorage.getItem(KEYS.employeeId) == null)
//         localStorage.setItem(KEYS.employeeId, '0')
//     var id = parseInt(localStorage.getItem(KEYS.employeeId))
//     localStorage.setItem(KEYS.employeeId, (++id).toString())
//     return id;
// }

// export function getAllEmployees() {
//     if (localStorage.getItem(KEYS.employees) == null)
//         localStorage.setItem(KEYS.employees, JSON.stringify([]))
//     return JSON.parse(localStorage.getItem(KEYS.employees));
// }