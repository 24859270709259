import { AccountCircle } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React from 'react';

// Styled IconButton to handle icon styling
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
    cursor: 'default',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '8rem',
  },
}));

// Styled DialogActions to handle button styling
const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'center',
}));

const ConfirmDialog = ({ confirmDialog, setConfirmDialog }) => {
  const handleClose = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
  };

  return (
    <Dialog open={confirmDialog.isOpen} PaperProps={{ sx: { p: 2 } }}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <StyledIconButton disableRipple>
          <AccountCircle color="inherit" />
        </StyledIconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
      </DialogContent>
      <StyledDialogActions>
        <Button sx={{ color: 'secondary.main' }} onClick={handleClose}>No</Button>
        <Button sx={{ color: 'primary.main' }} onClick={confirmDialog.onConfirm}>Yes</Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
