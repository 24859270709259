import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ArrowBackRounded } from '@mui/icons-material';
import axios from 'axios';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';
import { useSelector } from 'react-redux';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.vasantslabels.com/">
        vasantslabels
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const logoStyle = {
  width: '150px',
  height: 'auto',
};

const SignIn = ({redirectNavigation,setRedirectNavigation,setUser,setSpinnerActive,url}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const zeroValueCartItems = useSelector((state) => state.cart.zeroValueCartItems);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [isOpenSpinner,setOpenSpinner]=useState(true);
  const [data, setLoginData] = useState({
    username: "",
    password: "",
  });

  const formSubmit = async(e) => {
        e.preventDefault();
        const logindata={
          username: data.username,
          password: data.password
        }
        await axios.post(`${url}`,logindata).then(
          async response => {
            console.log(response);
            if(url==="authenticate"){
                localStorage.setItem('token',response.data.token);
                await checkCartItemAndAddToCartSection();
                setUser(response.data);
                setSpinnerActive(true);
                setOpenSpinner(false);
                if(redirectNavigation) {
                  navigate(redirectNavigation);
                  setRedirectNavigation("");
                } else {
                  navigate('/');
                }
            }else{
              
              if(response.data.status){
                localStorage.setItem('token',response.data.token);
                localStorage.setItem('tempUsername',response.data.username);
                navigate('/signin/brandBuyerForm');
              }else{
                alert("You have allready submitted response")
                return;
              }
                
            }
          }
        )
        .catch(error => {
          alert("Username or Password Is Incorrect Please Login Again")
        });

      };

const checkCartItemAndAddToCartSection = async()=> {
  if(cartItems?.length || zeroValueCartItems?.length) {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    if(cartItems?.length) {
      await axios.post(`endUser/cartDetails`, cartItems, config);
    }
    if(zeroValueCartItems?.length) {
      await axios.post(`endUser/cartDetails`, zeroValueCartItems, config);
    }
  }
}

  const InputEvent = (event) => {
    const { name, value } = event.target;

    setLoginData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Button
          startIcon={<ArrowBackRounded />}
          component="a"
          onClick={()=>{navigate("/");}}
          sx={{ alignSelf: 'start' }}
        >
          Back to "Home"
        </Button>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={formSubmit}>
       
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            id="username"
            label="login Id"
            name="username"
            value={data.username}
            onChange={InputEvent}
            autoFocus
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={data.password}
            onChange={InputEvent}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
        
          <Grid container>
            <Grid item xs>
              <NavLink to="/forgotPassword" variant="body2">
                Forgot password?
              </NavLink>
            </Grid>
            <Grid item>
                
                  {
                    url==="authenticate" ? 
                    ( 
                    <NavLink to="/signup/user" variant="body2">
                    {"Don't have an account? Sign Up"}
                    </NavLink>
                    )
                    : null
                }

                
                {
                    url==="authenticateBuyer" ?
                    (
                    <NavLink to="/signup/brandBuyer" variant="body2">
                    {"Don't have an account? Sign Up"}
                    </NavLink>
                    ): null
                }
            </Grid>
          </Grid>
        </form>
  
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default (SignIn);