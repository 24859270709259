import React, { useState } from "react";
import { AppBar, Badge, Box, Button, Divider, Drawer, IconButton, InputBase, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SearchIcon from "@mui/icons-material/Search";
import VasantsLabelsLogo from "../images/VasantsLabelsLogo.jpg";
import CartIcon from "./cart-icon/cart-icon.component";
import CartDropdown from "./cart-dropdown/cart-dropdown.component";
import { useDispatch, useSelector } from "react-redux";
import { setShoppingData } from "../store/slices/shop/ShopSlice";
import { addUser } from "../store/slices/user/UserSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { clearCart } from "../store/slices/cart/CartSlice";
import MenuIcon from '@mui/icons-material/Menu';
import { AddShoppingCart, BookmarkBorder } from "@mui/icons-material";


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff !important",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add subtle shadow
  },
  logo: {
    cursor: "pointer", // Show pointer cursor on logo
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0, // Remove right margin on small screens
    },
    "& img": {
      width: 120,
      height: "auto",
      borderRadius: 5, // Optional: Add border radius
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f2f2f2",
    "&:hover": {
      backgroundColor: "#e0e0e0", // Darken background on hover
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Header = (props) => {
  const navigate = useNavigate();
  /** redux */
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const hidden = useSelector((state) => state.cart.hidden);

  const { searchInput, setFilterFn } = props;
  const [isLogout, setisLogout] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const classes = useStyles();
  const [category, setCategory] = useState([]);
  const [data, setData] = useState();
  const [open, setOpen] = React.useState(false);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();

  };
  const handleIconImage = () => {
    navigate('/');
  }
  const onChangeLogout = () => {
    setAnchorEl(null);
    dispatch(setShoppingData([]));
    dispatch(clearCart());
    localStorage.clear()
    dispatch(addUser(undefined));
    setisLogout(true);
  }

  const handleSearch = e => {
    let target = e.target;
    setFilterFn({
      fn: items => {
        if (target.value === "")
          return items;
        else
          return items.filter(x => x.docno.toLowerCase().includes(target.value.toLowerCase()))
      }
    })
  }

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      <MenuItem onClick={() => { navigate("/") }}>Home</MenuItem>
      <MenuItem onClick={() => { navigate("/orders") }}>Your Orders</MenuItem>
      {currentUser && (
        <React.Fragment>
          <MenuItem onClick={() => { navigate("/profile/accountSettings") }}>Profile</MenuItem>
          <Divider />
          <MenuItem>
                      <Button
                        color="error"
                        variant="contained"
                        component="a"
                        onClick={onChangeLogout}
                        sx={{ width: '100%' }}
                      >
                        Logout
                      </Button>
                    </MenuItem>
        </React.Fragment>
      )}
    </Menu>
  );

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
              paddingRight: 3
            }}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}

            >
              <AccountCircleIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: '-18px',
              px: 0,
            }}
          >
            <div className={classes.logo} onClick={handleIconImage}>
              <img src={VasantsLabelsLogo} alt="Vasants Labels Logo" />
            </div>
            {searchInput && (
              <div className={classes.search}>
                <InputBase
                  placeholder="Search..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  startAdornment={<SearchIcon fontSize="small" />}
                  onChange={handleSearch}
                />
              </div>
            )}
            {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    
                    </Box> */}
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            {currentUser && (
              <Typography variant="body1" color="textPrimary" marginRight={2}>
                Hello, {currentUser.username}
              </Typography>
            )}
            {
              currentUser && (
                <MenuItem
                  onClick={() => { navigate("/orders") }}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Button startIcon={<BookmarkBorder />} variant="outlined">Your Orders</Button>
                </MenuItem>
              )
            }

            <IconButton >
              <Badge badgeContent={0} color="secondary">
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
            <IconButton >
              <Badge badgeContent={0} color="primary">
                <ChatBubbleOutlineIcon />
              </Badge>
            </IconButton>
            <CartIcon />

          </Box>
          <Box sx={{ display: { sm: '', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    flexGrow: 1,
                  }}
                >
                  {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                </Box>

                {currentUser ? (
                  <React.Fragment>
                    <MenuItem>
                      <Typography variant="body1" color="textPrimary">
                        Hello, {currentUser.username}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { navigate("/orders") }}>Your Orders</MenuItem>
                    <MenuItem onClick={() => { navigate("/profile/accountSettings") }}>Profile</MenuItem>
                    <MenuItem onClick={() => {
                      navigate('/checkout');
                    }}>
                      <AddShoppingCart fontSize="small" /> Cart
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                      <Button
                        color="error"
                        variant="contained"
                        component="a"
                        onClick={onChangeLogout}
                        sx={{ width: '100%' }}
                      >
                        Logout
                      </Button>
                    </MenuItem>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <MenuItem onClick={() => { navigate("/") }}>Home</MenuItem>
                  </React.Fragment>

                )}
              </Box>
            </Drawer>
          </Box>


        </Toolbar>
      </AppBar>
      {hidden ? null : <CartDropdown />}
      {renderMenu}
      {
        (isLogout) ? <Navigate to="/" /> : ""
      }
    </>
  );
};

export default Header;