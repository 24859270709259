import React, { useState } from "react";
import {
    TextField,
    Button,
    MenuItem,
    Typography,
    Box,
    Grid,
    Paper,
    IconButton,
} from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import axios from "axios";
import { FreightService } from "../../constants/VlplConstants";

const FreightCalculationForm = ({ freightService, saveFreightData }) => {
    const [formData, setFormData] = useState({
        courierName: "",
        serviceName: "",
        country: "india",
        fuelSurcharge: "",
        currencyAdjustment: "",
        minChargeableWeight: "",
        insuranceCharges: "",
        docketCharges: "",
        clearanceCharges: "",
        freightCharges: [],
        excelFile: null,
        freightService: freightService
    });

    const [freightCharge, setFreightCharge] = useState({ zone: "", price: "" });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (
                key !== "freightCharges" &&
                key !== "excelFile" &&
                !formData[key]
            ) {
                newErrors[key] = "This field is required";
            }
        });

        if (formData.freightCharges.length === 0) {
            newErrors.freightCharges = "Add at least one freight charge";
        }

        if (!formData.insuranceCharges) {
            newErrors.insuranceCharges =
                "Provide either insurance charges in Rs or as a percentage";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFreightChargeChange = (e) => {
        const { name, value } = e.target;
        setFreightCharge({
            ...freightCharge,
            [name]: value,
        });
    };

    const addFreightCharge = () => {
        if (
            !freightCharge.zone ||
            !freightCharge.price ||
            formData.freightCharges.some((fc) => fc.zone === freightCharge.zone)
        ) {
            setErrors({
                ...errors,
                freightCharges: "Zone must be unique and both fields are required",
            });
            return;
        }

        setFormData({
            ...formData,
            freightCharges: [...formData.freightCharges, freightCharge],
        });
        setFreightCharge({ zone: "", price: "" });
        setErrors({});
    };

    const handleFileUpload = (e) => {
        setFormData({ ...formData, excelFile: e.target.files[0] });
    };

    const handleSubmit = () => {
        if (validateForm()) {
            console.log("Form Data:", formData);
            const { excelFile, ...data } = formData;

            if (excelFile) {
                saveFreightData(data, excelFile);
            } else {
                console.error("Excel file is missing.");
                alert("Excel file is missing.");
            }
        }
    };


    // const saveFreightData = async (form, file) => {
    //     const data = new FormData();
    //     data.append('freightData', new Blob([JSON.stringify(form)], { type: 'application/json' }));
    //     data.append('file', file);

    //     try {
    //         const response = await axios.post('freightCalculation/save', data, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 Authorization: 'Bearer ' + localStorage.getItem('token')
    //             },
    //         });
    //         console.log('Data saved successfully:', response.data);
    //         alert(`${formData.freightService?.toUpperCase()} Data saved successfully`)
    //         handleCloseDialog();
    //     } catch (error) {
    //         console.error('Error saving data:', error);
    //     }
    // };


    const isFormComplete = () => {
        // Ensure all required fields are non-empty
        return (
            formData.courierName &&
            formData.serviceName &&
            formData.country &&
            formData.fuelSurcharge &&
            formData.currencyAdjustment &&
            formData.minChargeableWeight &&
            formData.insuranceCharges &&
            formData.docketCharges &&
            formData.clearanceCharges &&
            formData.freightService &&
            formData.freightCharges.length > 0 // At least one freight charge
        );
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: 700, margin: "auto" }}>
            <Typography variant="h5" sx={{ marginBottom: 3 }}>
                Freight Calculation Form
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Courier Name"
                        name="courierName"
                        fullWidth
                        value={formData.courierName}
                        onChange={handleInputChange}
                        error={!!errors.courierName}
                        helperText={errors.courierName}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Service Name"
                        name="serviceName"
                        fullWidth
                        value={formData.serviceName}
                        onChange={handleInputChange}
                        error={!!errors.serviceName}
                        helperText={errors.serviceName}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        select
                        label="Country"
                        name="country"
                        fullWidth
                        value={formData.country}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="india">India</MenuItem>
                        <MenuItem value="out of india">Out of India</MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Fuel Surcharge (%)"
                        name="fuelSurcharge"
                        type="number"
                        fullWidth
                        value={formData.fuelSurcharge}
                        onChange={handleInputChange}
                        error={!!errors.fuelSurcharge}
                        helperText={errors.fuelSurcharge}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Currency Adjustment (%)"
                        name="currencyAdjustment"
                        type="number"
                        fullWidth
                        value={formData.currencyAdjustment}
                        onChange={handleInputChange}
                        error={!!errors.currencyAdjustment}
                        helperText={errors.currencyAdjustment}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Minimum Chargeable Weight (Kgs)"
                        name="minChargeableWeight"
                        type="number"
                        fullWidth
                        value={formData.minChargeableWeight}
                        onChange={handleInputChange}
                        error={!!errors.minChargeableWeight}
                        helperText={errors.minChargeableWeight}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Insurance Charges (Rs)"
                        name="insuranceCharges"
                        type="number"
                        fullWidth
                        value={formData.insuranceCharges}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Docket Charges (Rs)"
                        name="docketCharges"
                        type="number"
                        fullWidth
                        value={formData.docketCharges}
                        onChange={handleInputChange}
                        error={!!errors.docketCharges}
                        helperText={errors.docketCharges}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Clearance Charges (Rs)"
                        name="clearanceCharges"
                        type="number"
                        fullWidth
                        value={formData.clearanceCharges}
                        onChange={handleInputChange}
                        error={!!errors.clearanceCharges}
                        helperText={errors.clearanceCharges}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography>Freight Charges</Typography>
                    <Box display="flex" gap={1} mt={1}>
                        <TextField
                            label="Zone"
                            name="zone"
                            value={freightCharge.zone}
                            onChange={handleFreightChargeChange}
                        />
                        <TextField
                            label="Price (Rs)"
                            name="price"
                            type="number"
                            value={freightCharge.price}
                            onChange={handleFreightChargeChange}
                        />
                        <Button variant="contained" onClick={addFreightCharge}>
                            Add
                        </Button>
                    </Box>
                    <Box mt={1}>
                        {formData.freightCharges.map((fc, index) => (
                            <Typography key={index}>
                                Zone: {fc.zone}, Price: {fc.price}
                            </Typography>
                        ))}
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        component="label"
                        disabled={!isFormComplete()}
                        startIcon={<UploadFile />}
                    >
                        Upload Excel
                        <input
                            type="file"
                            accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            hidden
                            onChange={handleFileUpload}
                        />
                    </Button>
                    <Grid item>
                        {formData.excelFile && (
                            <Typography variant="body1">{formData.excelFile?.name ?? ''}</Typography>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default FreightCalculationForm;
