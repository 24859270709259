import React, { useState } from "react";
import BrandBuyerForm from "./BrandBuyerForm";
// import { makeStyles,CssBaseline } from '@mui/material';
import { Paper, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import * as employeeService from "../../services/employeeService";
const theme = ({
    palette: {
      primary: {
        main: "#333996",
        light: '#3c44b126'
      },
      secondary: {
        main: "#f83245",
        light: '#f8324526'
      },
      background: {
        default: "#f4f5fd"
      },
    },
    overrides:{
      MuiAppBar:{
        root:{
          transform:'translateZ(0)'
        }
      }
    },
    props:{
      MuiIconButton:{
        disableRipple:true
      }
    }
  })
  
const useStyles = makeStyles(theme => ({
    pageContent: {
        // marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(5),
        // marginLeft: theme.spacing(25),
        // marginRight: theme.spacing(25),
        // paddingLeft: theme.spacing(5),
        // paddingBottom: theme.spacing(5),
        marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
        width: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
}))

const BrandBuyer = (props) => {
  const {setUser,setSpinnerActive}=props;
    const classes = useStyles();
    const num = (Math.floor((Math.random() * 10000)+(Math.random() * 10000)+(Math.random() * 100)) + 1);
    const [randomNumber,setRandomNumber]= useState(num);
    const [Details,setDetails]=useState("Billing Details");

    return (
        <>
            <ThemeProvider theme={theme}>
            <main className={classes.layout}>
            <Paper className={classes.pageContent}>
            <BrandBuyerForm setUser={setUser} setSpinnerActive={setSpinnerActive} randomNumber={randomNumber} setRandomNumber={setRandomNumber} Details={Details} setDetails={setDetails} />
            </Paper>
            </main>
            </ThemeProvider>
        </>
    )
}
export default BrandBuyer;
