export const AwaitingOrdeStatus = "Awaiting Confirmation";
export const confirmStatus = "Confirm";
export const categoryArray = [
  {
    label: "Vasants Labels Products",
    subCategory: []
  },
  {
    label: "Brand Products",
    subCategory: []
  }
];
export const preDefinedAddressFormData = {
  AC_TYPE: "ACNT",
  AREA: "28",
  VAT_NO: "NO",
  ACNT_EMAIL: "N",
  FORM_NO : "NF",
  CREDIT_DAY: "0",
  DISCOUNT: "0",
  GRADE: "A",
  COURIER_YN: "N",
  COREF_YN: "N",
  EOREG_YN: "Y",
  EMISPO_YN: "Y",
  ETREG_YN: "Y",
  ENQ_BILL: "Y",
  A_I: "A",
  EX_COPY: "Y",
  COAC_YN: "N",
  BILL_TYPE: "S",
  BRND_VENDR: "D",
  AQTY_YN: "N",
  TRAN_LVAL: "Y",
  BSUBMIT: "N",
  ELITE: "N",
  KMS: "0",
  AREA_NM: "COURIER",
  NO_GST: "NO GST"
};

export const FreightService ={
  DP: "dp",
  APEX: "apex",
  SFC: "sfc",
  ELD: "eld",
  DHL_ZONE: "dhl_zone",
  DHL_EXP: "dhl_exp"
}