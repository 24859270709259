import React from 'react';

import { makeStyles } from '@mui/styles';
import { SpinnerContainer, SpinnerOverlay } from './spinner.styles';
const useStyles = makeStyles(theme => ({
  pageContent: {
      width:"100%"
  },
  searchInput: {
      width: '75%'
  },
  newButton: {
      position: 'absolute',
      right: '10px'
  },
  container: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(4),
    },
}))

const Spinner = () => (
  <SpinnerOverlay>
    <SpinnerContainer />
  </SpinnerOverlay>
);

export default Spinner;
