import React from 'react'
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"; //aj doute need to fix
// import DateFnsUtils from "@date-io/date-fns";

export default function DatePicker(props) {

    // const { name, label, value, onChange } = props


    // const convertToDefEventPara = (name, value) => ({
    //     target: {
    //         name, value
    //     }
    // })

    return (
        <div></div>
        // <MuiPickersUtilsProvider utils={DateFnsUtils}>
        //     <KeyboardDatePicker disableToolbar variant="inline" inputVariant="standard"
        //         label={label}
        //         format="MMM/dd/yyyy"
        //         name={name}
        //         value={value}
        //         onChange={date =>onChange(convertToDefEventPara(name,date))}

        //     />
        // </MuiPickersUtilsProvider>
    )
}
