import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Chart from '../Chart';
import Deposits from '../Deposits';
import Orders from '../Orders';
import Products from '../Products';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Title from '../Title';
import SuspectAcmast from '../SuspectAcmast';
import axios from 'axios';
import { Button, FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import { formatDate, formatTime, getConfigurationHeader } from '../../../services/CommonService';
import { error } from 'jquery';
import Notification from '../../Notification';

import ExcelJS from 'exceljs';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.vasantslabels.com/">
        vasantslabels
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const useStyles = makeStyles((theme) => ({

  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const DashboardHome = () => {
  const classes = useStyles();
  const [showProducts, setShoProducts] = useState(false);
  const [showSuspectAcmast, setShowSuspectAcmast] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [date, setDate] = useState();
  const [dateList, setdateList] = useState([]);


  useEffect(() => {
    getAllUpdatedBYAccountsTime(true);
  }, []);

  const getAllUpdatedBYAccountsTime = (isInitial) => {
    axios.get('getAllUpdatedBYAccountsTime', getConfigurationHeader()).then(res => {
      if (res.data) {
        setdateList(res.data);
        if(!isInitial && res.data.length) {
          setDate(res.data[res.data.length - 1]);
          downloadSumittedAccounts(res.data[res.data.length - 1]);
        }
      }
    }).catch(error => {
    });
  }

  const handleSubmitShowProduct = async () => {
    setShoProducts(!showProducts);
  }
  const handleSubmitShowSuspectAcmast = () => {
    setShowSuspectAcmast(!showSuspectAcmast);
  }

  const handleSubmitAccountCode = (Acmast, setSuspectAcmast) => {
    setDate(undefined);
    setTimeout(() => {
      axios.post("submitSuspectAccounts", Acmast, getConfigurationHeader())
      .then(response => {
        if (response) {
          setSuspectAcmast([]);
          setNotify({
            isOpen: true,
            message: 'Account Codes Successfully Submitted 👍',
            type: 'success'
          });
          getAllUpdatedBYAccountsTime(false);
        } else {
          setNotify({
            isOpen: true,
            message: 'Server Error ! 👎',
            type: 'error'
          });
        }
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message: 'Server Error ! 👎',
          type: 'error'
        });
      });
    }, 0);
  }

  const downloadSumittedAccounts = (onSubmitSelectedDate) => {
    axios.get(`downloadSumittedAccounts?timestamp=${onSubmitSelectedDate}`, getConfigurationHeader(), { responseType: 'blob' })
      .then(response => {
        if (response.data) {
          generateExcelUsingData(response.data);
          setNotify({
            isOpen: true,
            message: 'Successfully Downloaded Excel with Newly Added Account Details 👍',
            type: 'success'
          });
        }
      })
      .catch(error => {
        console.error('Download error:', error);
        setNotify({
          isOpen: true,
          message: 'Server Error ! 👎',
          type: 'error'
        });
      });
  }

  const generateExcelUsingData = async (data) => {


    // Create a new workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add headers
    worksheet.addRow([
      'AC_TYPE',
      'AC_CODE',
      'AC_NAME',
      'AC_ADDR1',
      'AC_ADDR2',
      'AC_ADDR3',
      'AC_ADDR4',
      'COUNTRY',
      'AREA',
      'TEL_NO',
      'MOBILE_NO',
      'FAX_NO',
      'EMAIL',
      'VAT_NO',
      'PROP_NAME',
      'PURC_MANGR',
      'ACCOUNTANT',
      'ACNT_EMAIL',
      'MS_OMS',
      'FORM_NO',
      'CREDIT_DAY',
      'DISCOUNT',
      'GRADE',
      'FACT_NAME',
      'FPERSON',
      'FADDR1',
      'FADDR2',
      'FADDR3',
      'FADDR4',
      'FTEL_NO',
      'PICK',
      'COURIER_YN',
      'REMARK1',
      'COREF_YN',
      'EOREG_YN',
      'EMISPO_YN',
      'ETREG_YN',
      'GRP_CODE',
      'ENQ_BILL',
      'PORD_YN',
      'A_I',
      'EX_COPY',
      'CURRENCY',
      'BVUSER_CD',
      'BVUSER_NM',
      'BCHK_PWD',
      'FRGT_ZONE',
      'COAC_YN',
      'PARENT_CD',
      'BILL_TYPE',
      'BRND_VENDR',
      'AQTY_YN',
      'TRAN_LVAL',
      'COURIER',
      'AC_NO',
      'BILL_CODE',
      'EMAIL1',
      'EMAIL2',
      'GST_NO',
      'CITY',
      'STATE',
      'ZIP',
      'BILL_TO',
      'BSUBMIT',
      'CUSTOMER',
      'ELITE',
      'KMS',
      'SSTYPE',
      'TMODE',
      'TGST_NO',
      'TRANS_ID',
      '', // extra column added (as per discussion priyesh)
      'STATE_NM',
      'CITY_NM',
      'COUNTRY_NM',
      'AREA_NM',
      'CURR_NM',
      'BILL_TONM',
      'STYPE_NM',
      'TMODE_NM',
      'PERSON',
      'TEL_NOA',
      'EMAILA',
      'USER_CD',
      'USER_PWD',
      'DATE', // extra column added (as per discussion priyesh)
      'TIME', // extra column added (as per discussion priyesh)
    ]);

    // Add data rows
    data.forEach((account) => {
      worksheet.addRow([
        account.actype ? account.actype : '',
        account.accode ? account.accode : '',
        account.acname ? account.acname : '',
        account.acaddr1 ? account.acaddr1 : '',
        account.acaddr2 ? account.acaddr2 : '',
        account.acaddr3 ? account.acaddr3 : '',
        account.acaddr4 ? account.acaddr4 : '',
        account.country ? account.country : '',
        account.area ? account.area : '',
        account.telno ? account.telno : '',
        account.mobileno ? account.mobileno : '',
        account.faxno ? account.faxno : '',
        account.email ? account.email : '',
        account.vatno ? account.vatno : '',
        account.propname ? account.propname : '',
        account.purcmangr ? account.purcmangr : '',
        account.accountant ? account.accountant : '',
        account.acntemail ? account.acntemail : '',
        account.msoms ? account.msoms : '',
        account.formno ? account.formno : '',
        account.creditday ? account.creditday : '',
        account.discount ? account.discount : '',
        account.grade ? account.grade : '',
        account.factname ? account.factname : '',
        account.fperson ? account.fperson : '',
        account.faddr1 ? account.faddr1 : '',
        account.faddr2 ? account.faddr2 : '',
        account.faddr3 ? account.faddr3 : '',
        account.faddr4 ? account.faddr4 : '',
        account.ftelno ? account.ftelno : '',
        account.pick ? account.pick : '',
        account.courieryn ? account.courieryn : '',
        account.remark1 ? account.remark1 : '',
        account.corefyn ? account.corefyn : '',
        account.eoregyn ? account.eoregyn : '',
        account.emispoyn ? account.emispoyn : '',
        account.etregyn ? account.etregyn : '',
        account.grpcode ? account.grpcode : '',
        account.enqbill ? account.enqbill : '',
        account.pordyn ? account.pordyn : '',
        account.ai ? account.ai : '',
        account.excopy ? account.excopy : '',
        account.currency ? account.currency : '',
        account.bvusercd ? account.bvusercd : '',
        account.bvusernm ? account.bvusernm : '',
        account.bchkpwd ? account.bchkpwd : '',
        account.frgtzone ? account.frgtzone : '',
        account.coacyn ? account.coacyn : '',
        account.parentcd ? account.parentcd : '',
        account.billtype ? account.billtype : '',
        account.brndvendr ? account.brndvendr : '',
        account.aqtyyn ? account.aqtyyn : '',
        account.tranlval ? account.tranlval : '',
        account.courier ? account.courier : '',
        account.acno ? account.acno : '',
        account.billcode ? account.billcode : '',
        account.email1 ? account.email1 : '',
        account.email2 ? account.email2 : '',
        account.gstno ? account.gstno : '',
        account.city ? account.city : '',
        account.state ? account.state : '',
        account.zip ? account.zip : '',
        account.billto ? account.billto : '',
        account.bsubmit ? account.bsubmit : '',
        account.customer ? account.customer : '',
        account.elite ? account.elite : '',
        account.kms ? account.kms : '',
        account.sstype ? account.sstype : '',
        account.tmode ? account.tmode : '',
        account.tgstno ? account.tgstno : '',
        account.transid ? account.transid : '',
        '',
        account.statenm ? account.statenm : '',
        account.citynm ? account.citynm : '',
        account.countrynm ? account.countrynm : '',
        account.areanm ? account.areanm : '',
        account.currnm ? account.currnm : '',
        account.billtonm ? account.billtonm : '',
        account.stypenm ? account.stypenm : '',
        account.tmodenm ? account.tmodenm : '',
        account.person ? account.person : '',
        account.telnoa ? account.telnoa : '',
        account.emaila ? account.emaila : '',
        account.usercd ? account.usercd : '',
        account.userpwd ? account.userpwd : '',
        formatDate(new Date()),
        formatTime(new Date()),
      ]);
    });

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();

    // Convert buffer to Blob
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // Create download link
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ACMASTDT.xlsx';

    // Trigger download
    a.click();

    // Release object URL
    URL.revokeObjectURL(url);
  }

  const handleSelectDate = (event) => {
    const { value } = event.target;
    setDate(value);
  }

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper className={fixedHeightPaper}>
              <Chart />
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
              <Deposits />
            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Orders />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>

              <Title>Show All Products</Title>
              {
                showProducts ? <ArrowDropUpIcon onClick={handleSubmitShowProduct} /> : <ArrowDropDownIcon onClick={handleSubmitShowProduct} />
              }
              {
                showProducts ? <Products /> : null
              }

            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Title>Show All Suspect Accounts</Title>
          </Grid>
          <Grid item xs={4}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <FormLabel htmlFor="date" required>Select Date</FormLabel>
            <Select
              labelId="date"
              id="date"
              name='date'
              label="Date"
              required
              onChange={handleSelectDate}
              fullWidth
            >
              {dateList?.map((date, index) => (
                <MenuItem key={index} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            
          </Grid>
          <Grid item xs={4}>
            <Button disabled={!date} onClick={()=> {downloadSumittedAccounts(date)}} variant='outlined'>Download Submitted Accounts</Button>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {
                showSuspectAcmast ? <ArrowDropUpIcon onClick={handleSubmitShowSuspectAcmast} /> : <ArrowDropDownIcon onClick={handleSubmitShowSuspectAcmast} />
              }
              {
                showSuspectAcmast ? <SuspectAcmast handleSubmitAccountCode={handleSubmitAccountCode} /> : null
              }


            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </main>
  );
}
export default (DashboardHome);