import React, { useState } from "react";
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Grid,
} from "@mui/material";
import FreightCalculationForm from "./FreightCalculationForm"; // Import your form
import axios from "axios";
import { UploadFile } from "@mui/icons-material";
import Spinner from "../spinner/spinner.component";

const initializeForm = {
    freightCharges: [],
    excelFile: null,
    freightService: undefined
};
const FreightComponent = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [spinnerActive, setSpinnerActive] = useState(false);
    const [selectedFreight, setSelectedFreight] = useState("");
    const [formData, setFormData] = useState({ ...initializeForm });

    // List of freight options
    const freightOptions = ["SFC", "SFC_LOCAL", "DP", "APEX", "DHL", "EDL"];

    // Open dialog and set selected freight
    const handleUploadClick = (option) => {
        setSelectedFreight(option);
        setFormData({ ...formData, freightService: option.toLocaleLowerCase() })
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedFreight("");
        setFormData({ ...initializeForm });
    };

    const handleFileUpload = (e) => {
        setFormData({ ...formData, excelFile: e.target.files[0] });
    };

    const handleSubmit = () => {

        console.log("Form Data:", formData);
        const { excelFile, ...data } = formData;

        if (excelFile) {
            saveFreightData(data, excelFile);
        } else {
            console.error("Excel file is missing.");
            alert("Excel file is missing.");
        }
    };

    const saveFreightData = async (form, file) => {
        const data = new FormData();
        data.append('freightData', new Blob([JSON.stringify(form)], { type: 'application/json' }));
        data.append('file', file);
        setSpinnerActive(true);
        try {
            const response = await axios.post('freightCalculation/save', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                },
            });
            console.log('Data saved successfully:', response.data);
            setSpinnerActive(false);
            alert(`${selectedFreight} Data saved successfully`)
            handleCloseDialog();
        } catch (error) {
            setSpinnerActive(false);
            console.error('Error saving data:', error);
        }
    };

    return (
        <>
        {
             spinnerActive ?
             <Spinner />
             : 
             <Box sx={{ width: "100%", p: 2 }}>
                 <Typography variant="h4" gutterBottom>
                     Freight Options
                 </Typography>
                 <List>
                     {freightOptions.map((option) => (
                         <ListItem key={option} divider>
                             <ListItemText primary={option} />
                             <ListItemSecondaryAction>
                                 <Button
                                     variant="outlined"
                                     color="primary"
                                     sx={{ mr: 1 }}
                                     onClick={() => alert(`View details for ${option}`)}
                                 >
                                     View
                                 </Button>
                                 <Button
                                     variant="contained"
                                     color="secondary"
                                     onClick={() => handleUploadClick(option)}
                                 >
                                     Upload
                                 </Button>
                             </ListItemSecondaryAction>
                         </ListItem>
                     ))}
                 </List>
     
                 {/* Upload Dialog */}
                 <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                     <DialogTitle>Upload - {selectedFreight}</DialogTitle>
                     <DialogContent>
                         {/* Render the already developed FreightCalculationForm */}
                         {
                             selectedFreight == 'EDL' ?
                                 <>
                                     <Grid item xs={12}>
                                         <Button
                                             variant="contained"
                                             component="label"
                                             startIcon={<UploadFile />}
                                         >
                                             Upload Excel
                                             <input
                                                 type="file"
                                                 accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                 hidden
                                                 onChange={handleFileUpload}
                                             />
                                         </Button>
                                         <Grid item>
                                             {formData.excelFile && (
                                                 <Typography variant="body1">{formData.excelFile?.name ?? ''}</Typography>
                                             )}
                                         </Grid>
                                     </Grid>
                                     <Grid item xs={12} className="mt-2">
                                         <Button
                                             variant="contained"
                                             color="primary"
                                             fullWidth
                                             onClick={handleSubmit}
                                         >
                                             Submit
                                         </Button>
                                     </Grid>
                                 </>
     
                                 :
                                 <FreightCalculationForm freightService={selectedFreight.toLocaleLowerCase()} saveFreightData={(form, file) => saveFreightData(form, file)} />
                         }
     
                     </DialogContent>
                 </Dialog>
             </Box>
        }
       
        </>
        
    );
};

export default FreightComponent;
