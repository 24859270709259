import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import axios from 'axios';
import InputLabel from '@mui/material/InputLabel';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.vasantslabels.com/">
        vasantslabels
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  note: {
    fontSize: 20,
    paddingTop: theme.spacing(2),
    textAlign: "center",
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [data, setData] = useState({
    email: "",
    username: "",
  });
  const InputEvent = (event) => {
    const { name, value } = event.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    const logindata = {
      username: data.username,
      to: data.email,
    }

    await axios.post('forgotPassword', logindata).then(
      response => {
        if (response.data != false) {
          alert("Password Successfully Send To Your Mail Id 👍")
        } else {
          alert("Un-Successful Please Check Your EMail-Id or Username !👎")
        }
      }
    )
      .catch(error => {
        if (error.response != false) {
          alert("Password Successfully Send To Your Mail Id 👍")
        } else {
          alert("Un-Successful Please Check Your EMail-Id or Username !👎")
        }
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <form className={classes.form} onSubmit={formSubmit}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={data.email}
              onChange={InputEvent}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
          <InputLabel className={classes.note} >OR</InputLabel>
          <form className={classes.form} onSubmit={formSubmit}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              name="username"
              label="Login Id"
              type="username"
              id="username"
              value={data.username}
              onChange={InputEvent}

            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

export default ForgotPassword;
