export const getConfigurationHeader = () => {
    if (localStorage.getItem('token')) {
        return {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        };
    } else {
        return undefined;
    }
}

export const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
};

export const formatTime = (date) => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    return date.toLocaleTimeString('en-GB', options);
};