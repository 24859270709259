import { createSlice } from "@reduxjs/toolkit";
import { addItemToCart, removeItemFromCart, addItemToCartMultiplyQuantity,addItemToCartUpdateQuantity, calculateAndgetCartDetails, fetchCartDetails } from './cart.utils';

const initialState = {
    hidden: true,
    cartItems: [],
    zeroValueCartItems: [],
    corefno:"",
}

const cartSlice = createSlice({

  name: "cart",
  initialState,
  reducers: {
    toggleHidden(state) {
        state.hidden = !state.hidden;
    },
    addItemToCartItems(state, action) {
      if (action.payload?.rate == 0) {
        state.zeroValueCartItems = addItemToCart(state.zeroValueCartItems, action.payload);
      } else {
        state.cartItems = addItemToCart(state.cartItems, action.payload);
      }
    },
    multiplyQuantity(state, action) {
      if (action.payload?.rate == 0) {
        state.zeroValueCartItems = addItemToCartMultiplyQuantity(state.zeroValueCartItems, action.payload);
      } else {
        state.cartItems = addItemToCartMultiplyQuantity(state.cartItems, action.payload);
      }
    },
    updateQuantity(state, action) {
      if (action.payload?.rate == 0) {
        state.zeroValueCartItems = addItemToCartUpdateQuantity(state.zeroValueCartItems, action.payload);
      } else {
        state.cartItems = addItemToCartUpdateQuantity(state.cartItems, action.payload);
      }
    },
    removeCartItem(state, action) {
      if (action.payload?.rate == 0) {
        state.zeroValueCartItems = removeItemFromCart(state.zeroValueCartItems, action.payload);
      } else {
        state.cartItems = removeItemFromCart(state.cartItems, action.payload);
      }
    },
    clearCartItem(state, action) {
      if (action.payload?.rate == 0) {
        if(action.payload.ordertype.toLowerCase==="without additional sheet"){
          state.zeroValueCartItems = state.zeroValueCartItems.filter(cartItem => cartItem.id !== action.payload.id);
        } else {
          state.zeroValueCartItems = calculateAndgetCartDetails(state.zeroValueCartItems, action.payload);
        };
      } else {
        if(action.payload.ordertype.toLowerCase==="without additional sheet"){
          state.cartItems = state.cartItems.filter(cartItem => cartItem.id !== action.payload.id);
        } else {
          state.cartItem = calculateAndgetCartDetails(state.cartItems, action.payload);
        };
      }
    },
    clearCart(state) {
      state.cartItems = [];
      state.zeroValueCartItems = [];
    },
    updateCorefNo(state, action) {
      state.corefno = action.payload;
    },
    getCartDetailsOnLoad(state) {
      state.cartItems = [];
      state.zeroValueCartItems = [];
      let cartData = fetchCartDetails(state.cartItems);
      cartData?.forEach(obj =>{
        if(obj.rate == 0) {
          state.zeroValueCartItems.push(obj);
        } else {
          state.cartItems.push(obj);
        }
      })
    }
  },
});


export default cartSlice.reducer;
export const {
  toggleHidden,
  addItemToCartItems,
  multiplyQuantity,
  updateQuantity,
  removeCartItem,
  clearCartItem,
  clearCart,
  updateCorefNo,
  getCartDetailsOnLoader 
} = cartSlice.actions;