import { Badge, IconButton } from '@mui/material';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleHidden } from '../../store/slices/cart/CartSlice';

const CartIcon =() => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const zeroValueCartItems = useSelector((state) => state.cart.zeroValueCartItems);
  const handleClickFunction = () => {
    dispatch(toggleHidden())
  };

  return(
    <IconButton color="primary" onClick={handleClickFunction}>
    <Badge badgeContent={cartItems.length + zeroValueCartItems.length} color="primary">
        <AddShoppingCart  fontSize="small"/>
    </Badge>
    </IconButton>
);};

export default (CartIcon);   
  