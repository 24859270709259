import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    shoppingData: [],
    isFetching: false,
    errorMessage: undefined,
    filteredItems:[],
    rootCategory: ""
}

const shopSlice = createSlice({

  name: "shop",
  initialState,
  reducers: {
    setShoppingData(state, action) {
        state.shoppingData = action.payload;
    },
    setRootCategory(state, action) {
      console.log(action.payload);
      state.rootCategory = action.payload;
    }
  },
});



export default shopSlice.reducer;
export const { setShoppingData, setRootCategory } = shopSlice.actions;
