import { Grid, Paper } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CollectionItem from '../collection-item/collection-item.component';

import ProductDetailsPdf from '../../pages/productDetailpage/ProductDetailsPdf';
import Popup from '../Popup';
// import ImageTransition from "../imageTransition/ImageTransition"; // aj dute need to fix
import { useTable } from "../useTable";
const useStyles = makeStyles((theme) => ({
  gridContainerItems: {
    paddingLeft: "40px",
  },
  div: {
    paddingTop: "23px",
    // paddingLeft: "40px",
    // paddingRight: "40px",
  },
  page: {
    width: '100%'
  },
  page2: {
    width: '20%'
  },
}));

const CollectionPreview  = ({ filterFn, infletedRateApply, key, cat, categoriesData}) => {

  const classes = useStyles();

  /** redux */
const currentUser = useSelector((state) => state.users.currentUser);
const shoppingData = useSelector((state) => state.shop.shoppingData);

  const [records, setRecords] = useState(categoriesData);
  const [openPopup, setOpenPopup] = useState(false);
  const [docno, setDocno] = useState('');
  useEffect(()=>{
    // if(categoriesData!y)
    // getRecordBasedOnCategory();
  },[])

  const openProductDetailPdf = (docno) => {
    if (docno) {
      setOpenPopup(true);
      setDocno(docno);
    }
  }
  // const getRecordBasedOnCategory = () =>{
  //   const config ={
  //      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
  //   }
  //   if (cat) {
  //     axios.get(`productsBasedOnCategory/${cat}`,config).then(
  //       response => {
  //         setRecords(response);
  //     }
  //     )
  //   .catch(error =>{
  //       alert("Category data is missing");
  //       setRecords(shoppingData);
  //   })
  //   } else {
  //     setRecords(shoppingData);
  //   }
  //  }
 
  const pages = [18,24];
  const {
    TblContainer,
    TblPagination,
    recordsAfterPagingAndSorting,
} = useTable(records,pages,null, filterFn);
    return (
      <div className={classes.div}>
      <Paper className={classes.page}>
   <TblContainer>
      <Grid
        container
        spacing={3}
        className={classes.gridContainerItems}
      > {/*row Grid 1 starts here */}
      {
        recordsAfterPagingAndSorting().map((item,ind) => (
                <CollectionItem infletedRateApply={infletedRateApply} key={ind} item={item} openProductDetailPdf={openProductDetailPdf}/>
        ))
      }
        <Grid item xs={12} sm={12} >
      <TblPagination />
      </Grid>
     
      </Grid> 
      </TblContainer>
      </Paper>
      <Paper className={classes.page}>
      <Grid container direction="column" >
      <Grid item xs={12} sm={12} >
      {/* <ImageTransition /> */}
      </Grid>
      </Grid>
      </Paper>
        {
          docno && (
            <Popup
              title=""
              openPopup={openPopup}
              setDocno={setDocno}
              setOpenPopup={setOpenPopup}

            >
              <ProductDetailsPdf docno={docno} />
            </Popup>
          )
        }
      
      </div>
    );
};

export default (CollectionPreview);