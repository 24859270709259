import React,{useState,useEffect} from 'react';


import { Paper, TableBody, TableRow, TableCell, Toolbar, InputAdornment,InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Notification from "../Notification";
import ConfirmDialog from "../ConfirmDialog";
import {useTable} from "../useTable";
import { Search } from "@mui/icons-material";
import Controls from "../controls/Controls";
import Popup from "../Popup";
import Productpopup from "../ProductsInfo/ProductPopup";
import * as employeeService from "../../services/employeeService";
import axios from 'axios';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
  uploadStatus: {
    color: '#ff5252',
    fontSize: 12,
  },
  searchInput: {
      width: '50%'
  },
  newButton: {
      position: 'absolute',
      right: '10px'
  }
}));

const headCells = [
  { id: 'BRAND', label: 'BRAND NAME'},
  { id: 'DOC_NO', label: 'DOC_NO' },
  { id: 'ORD_INST1', label: 'ORD_INST1', disableSorting: true },
  { id: 'SIZES', label: 'SIZES', disableSorting: true },
  { id: 'IMAGE STATUS', label: 'IMAGE STATUS', disableSorting: true },
  { id: 'UPLOAD IMAGES', label: 'UPLOAD IMAGES', disableSorting: true },
  { id: 'GROUP NAME', label: 'GROUP NAME', disableSorting: true },
  {id:'SEQUENCE', label:'SEQUENCE', disableSorting:true},
  { id: 'actions', label: 'Actions', disableSorting: true }
];

const Products = (props) => {
  
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  
  const[value,setValue]=useState();
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: ''});
  const [upload,setUpload]= useState(false);
  const [groupname,setGroupName]=useState([]);
  const [values, setValues] = useState();
  const [state,setState]=useState(null);
  const [selectedItem, setItem] = useState(null);
  const pages = [5, 10, 25];
  const {
      TblContainer,
      TblHead,
      TblPagination,
      recordsAfterPagingAndSorting
  } = useTable(records, pages, headCells, filterFn); 
const openInPopup = item => {
  setRecordForEdit(item)
  setOpenPopup(true)
}
  useEffect( () => {
    getAllProducts();
    handleInputChangeForGroupname();
    // handleChange();
    },[upload]);

const handleSequenceChange = async(sequenceNo, resetForm) => {
  if(recordForEdit!=null){
    let data={
      docno:recordForEdit.docno,
      sequence:sequenceNo,
      groupname:recordForEdit.groupname
    };
    // console.log(data);
    let config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    // console.log(data);
    await axios.post(`updateSequence`,data,config)
        .then(res => { // then print response status
          // console.log(res.data);
          if(res.data != false) {
            let updateRecord = records.map((val) => {
              if(val.docno == data.docno) {
                return {
                  ...val,
                  sequence:sequenceNo
                };
              } else {
                return val;
              }
            });
            setRecords(updateRecord);
            setNotify({
              isOpen: true,
              message: 'Sequence Updated Successfully👍',
              type: 'success'
            });
            // setUpload(!upload);
            // console.log(res.data);
          } else {
            setNotify({
              isOpen: true,
              message: 'Same sequence is allready allocated to same brand with the same groupname 👎',
              type: 'error'
            });
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'Unsuccessfull 👎',
            type: 'error'
          });
    });
    
    resetForm();
    setOpenPopup(false);
    setTimeout(() => {
      setRecordForEdit(null);
    }, 0);
  }
}

const handleSelectClick = item => {
  setItem(item);
} 
const handleChange = async(event) =>{
  if(selectedItem && event.target.value) {
    let data={
      docno:selectedItem.docno,
      sequence:selectedItem.sequence,
      groupname:event.target.value
    };
    // console.log(data);
    let config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    // console.log(data);
    await axios.post(`groupName`,data,config)
        .then(res => { // then print response status
          console.log(res.data);
          if(res.data != false){
            let updateRecord = records.map((val) => {
              if(val.docno == data.docno) {
                return {
                  ...val,
                  groupname:event.target.value,
                  sequence:res.data
                };
              } else {
                return val;
              }
            });
            setRecords(updateRecord);
            setNotify({
              isOpen: true,
              message: 'Groupname Updated Successfully👍',
              type: 'success'
            });
            // setUpload(!upload);
            console.log(res.data);
          } else {
            setNotify({
              isOpen: true,
              message: 'Unsuccessfull 👎',
              type: 'error'
            });
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'Unsuccessfull 👎',
            type: 'error'
          });
    });
    // setRecordForEdit(null);
    // // resetForm();
    // setOpenPopup(false);
  } 
}

const getAllProducts = async() =>{
    const config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    await axios.get("getAllProducts",config)
    .then(response=>{
      setRecords(response.data)
     console.log(response.data);
    })
    .catch(error => {
      alert("Server Error ! 👎")
    });
}
const handleInputChangeForGroupname = async()=>{
//  const Brandname="BABYHUG";
//  console.log(Brandname);
// if(!groupname.find(item => item.brand == Brandname)) {
  const config ={
    headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  await axios.get(`getBrandsCat`,config)
  .then(response=>{
    setGroupName(response.data);
    console.log(response.data);
    })
    .catch(errors => {
    console.log(errors);
    });
// }

}
  const addOrEdit = async(sizeslist, resetForm) => {
    if(recordForEdit != null){
      let sizeListArray = sizeslist?.toString().split('|');
      let data={
        docno:recordForEdit.docno,
        sizesString:sizeListArray         
      }
      let config ={
        headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
      }
      await axios.post(`sizesUpload`,data,config)
          .then(res => { // then print response status
            if(res.data === true) {
              setNotify({
                isOpen: true,
                message: 'Sizes Uploaded Successfully 👍',
                type: 'success'
              });
              // alert("Sizes Uploaded Successfully ! 👍");
              setUpload(!upload);
            }
          })
          .catch(error => {
            setNotify({
              isOpen: true,
              message: 'Sizes Are Not-Uploaded 👎',
              type: 'error'
            })
      });
      resetForm();
      setOpenPopup(false);
      setTimeout(() => {
        setRecordForEdit(null);
      }, 0);
    }
    
}

const insertInflatedRate = async(rate, resetForm) => {
  if(recordForEdit!=null){
    let data={
      docno:recordForEdit.docno,
      inflatedRate:parseFloat(rate)
    }
    let config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    // console.log(data);
    await axios.post(`inflatedRate`,data,config)
        .then(res => { // then print response status
          if(res.data===true){
            setNotify({
              isOpen: true,
              message: 'Rate Inflation Successfully 👍',
              type: 'success'
            })
            let updateRecord = records.map((val) => {
              if(val.docno == data.docno) {
                return {
                  ...val,
                  inflatedRate: parseFloat(rate)
                };
              } else {
                return val;
              }
            });
            setRecords(updateRecord);
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'Unsuccessfull 👎',
            type: 'error'
          })
    });
    resetForm();
    setOpenPopup(false);
    setTimeout(() => {
      setRecordForEdit(null);
    }, 0);
  } 
}
const insertSequence = async(sequence, resetForm) => {
  if(recordForEdit!=null){
    let data={
      docno:recordForEdit.docno,
      sequence:parseFloat(sequence)
    }
    let config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    // console.log(data);
    await axios.post(`getSequence`,data,config)
        .then(res => { // then print response status
          if(res.data){
            setNotify({
              isOpen: true,
              message: 'Sequence Entered Successfully 👍',
              type: 'success'
            })
            setUpload(!upload);
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'Unsuccessfull 👎',
            type: 'error'
          })
    });
    resetForm();
    setOpenPopup(false);
    setTimeout(() => {
      setRecordForEdit(null);
    }, 0);
  } 
}

const handleSearch = e => {
  let target = e.target;
  setFilterFn({
      fn: items => {
          if (target.value === "")
              return items;
          else
              return items.filter(x => x.docno.toLowerCase().includes(target.value))
      }
  })
}
  
  const handleImageUpload = async(event) =>{
    
    const {id} = event.target;
    let { files } = event.target;

        let formData = new FormData();
        _.forEach(files, file => {
            formData.append('files', file);
        });
        const config ={
          headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        await axios.post(`imagesUpload/${id}`,formData,config)
        .then(res => { // then print response status
          if(res.data===true){
            setNotify({
              isOpen: true,
              message: 'Images Uploaded Successfully 👍',
              type: 'success'
            })
            let updateRecord = records.map((val) => {
              if(val.id == id) {
                return {
                  ...val,
                  imageStatus: true
                };
              } else {
                return val;
              }
            });
            setRecords(updateRecord);
          }
        })
        .catch(error => {
          setNotify({
            isOpen: true,
            message: 'Images Are Not-Uploaded 👎',
            type: 'error'
          })
        });
  }
  const onDelete = id => {
    setConfirmDialog({
        ...confirmDialog,
        isOpen: false
    })
    employeeService.deleteProduct(id);
    setUpload(!upload);
    setNotify({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
    })
}

const handleUpdateMoreInfo = (recordForEditingPasses, checked) => {
  if(recordForEditingPasses) {
    let index = records.findIndex(res => res.docno === recordForEditingPasses.docno);
    if(index !== undefined && index !== -1) {
      let tempRecords = [...records];
      tempRecords[index].moreinfo = checked;
      recordForEdit && setRecordForEdit({...recordForEdit, moreinfo:checked});
      setTimeout(() => {
        setRecords(tempRecords);
      }, 0);
    }
  }
} 

  return (
    <>
      <Paper>
                  <Toolbar>
                    <Controls.Input
                        label="Search Products"
                        className={classes.searchInput}
                        InputProps={{
                            startAdornment: (<InputAdornment position="start">
                                <Search />
                            </InputAdornment>)
                        }}
                        onChange={handleSearch}
                    />
                </Toolbar>
      <TblContainer>
                    <TblHead />
                    <TableBody>
                        {
                            recordsAfterPagingAndSorting().map(item =>
                                (
                                 groupname.length ?
                                <TableRow key={item.id}>
                                  <TableCell>{item.brand}</TableCell>
                                  <TableCell>{item.docno}</TableCell>
                                  <TableCell>{item.ordinst1}</TableCell>
                                  <TableCell>{item.sizes != null ? <InputLabel>{item.sizes.join().replaceAll(",", "|")}</InputLabel> : <InputLabel className={classes.uploadStatus}>{(item.ordertype == "With additional sheet") ? "Sizes not-uploaded" : null}</InputLabel>}</TableCell>
                                  <TableCell>{item.imageStatus ? <InputLabel>Uploaded</InputLabel> : <InputLabel className={classes.uploadStatus}>Not-uploaded</InputLabel>}</TableCell>
                                  <TableCell><input multiple type="file" onChange={handleImageUpload} name={item.docno} id={item.id} /></TableCell>
                                  <TableCell>
                                    <select onChange={handleChange} onClick={() => { handleSelectClick(item) }}>
                                      <option value={item.groupname}>{item.groupname}</option>
                                      {groupname.find(groupNameList => groupNameList.brand == item.brand)?.data?.map(group => (
                                        <option key={group.id} id={group.id} value={group.grpname}>{group.grpname}</option>
                                      ))
                                      }
                                    </select>
                                  </TableCell>
                                  <TableCell>
                                    {item.sequence}
                                    {/* {item.sequence!=null ? <InputLabel>{item.sequence}</InputLabel>:null} */}
                                    {/* {item.sequence!=0?<InputLabel>{item.sequence}</InputLabel>:null}
                             */}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      item.ordertype.toLowerCase() === "with additional sheet" ?
                                        <Controls.ActionButton
                                          color="primary"
                                          onClick={() => { openInPopup(item) }}>
                                          <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                        :
                                        <Controls.ActionButton
                                          onClick={() => { openInPopup(item) }}>
                                          <EditOutlinedIcon fontSize="small" />
                                        </Controls.ActionButton>
                                    }
                                    <Controls.ActionButton
                                      color="secondary"
                                      onClick={() => {
                                        setConfirmDialog({
                                          isOpen: true,
                                          title: 'Are you sure to delete this record?',
                                          subTitle: "You can't undo this operation",
                                          onConfirm: () => { onDelete(item.id) }
                                        })
                                      }}>
                                      <CloseIcon fontSize="small" />
                                    </Controls.ActionButton>

                                  </TableCell>
                                </TableRow>
                                : "")
                            )
                        }
                    </TableBody>
                </TblContainer>
                <TblPagination />
      </Paper>
      <Popup
        title={`Product Form {${recordForEdit ? recordForEdit.docno : ''}}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        {
          recordForEdit &&
          <Productpopup
            recordForEdit={recordForEdit}
            insertInflatedRate={insertInflatedRate}
            handleSequenceChange={handleSequenceChange}
            setNotify={setNotify}
            // insertSequence={insertSequence}
            handleUpdateMoreInfo={handleUpdateMoreInfo}
            addOrEdit={addOrEdit} />
        }

      </Popup>
      <Notification
                notify={notify}
                setNotify={setNotify}
      />
       <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
      /> 
    </>
  );
}

export default (Products);