import React, { useState,useEffect } from 'react'
import { makeStyles } from '@mui/styles';
import axios from 'axios';

export function useForm(initialFValues, initialUserDetails,initialcourierdetails, validateOnChange = false, validate, validateUserDetails,validatecourierTobeArranged,setCity) {

    const [values, setValues] = useState(initialFValues);
    const [data, setUserData] = useState(initialUserDetails);
    const [courierTobeArranged, setCourierTobeArranged] = useState(initialcourierdetails);
    const [errors, setErrors] = useState({});
    const [userError,setUserError]= useState({});
    const [courierError,setcourierError]= useState({});
    useEffect( () => {
        const config ={
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        axios.get(`/demoData`,config).then(
          response =>{
            setValues(response.data[0]);
          }
        )
        .catch(
          error =>{
            alert("Technical Error !!!");
          }
        );
    },[]);

    const handleInputChange = e => {
        const { name, value } = e.target
        if (validateOnChange)
            validate({ [name]: value })
            setValues({
                ...values,
                [name]: value
            })
    }

    const handleInputChangeforBrand = (e) =>{
        const { name, value } = e.target
        const parent_cd="parent_cd"
        const bvuser_cd="bvuser_cd"
        const bchk_pwd="bchk_pwd"
        const username=localStorage.getItem('tempUsername')
        setValues({
            ...values,
            [bvuser_cd]:username,
            [bchk_pwd]: username,
            [parent_cd]:value,
            [name]: value
        })
        if (validateOnChange){
            validate({ [name]: value })
        }     
    }

    const handleInputChangeforAccountName = (e) =>{
        const { name, value } = e.target
        const ac_name ="ac_name"
        setValues({
            ...values,
            [ac_name]:value,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const handleInputChangeforCountry = e =>{
        const { name, value } = e.target
        const cur= 'currency';
        // setValues({
        //     ...values,
        //     [name]: value
        // })
        if(value == "I001"){
            setValues({
                ...values,
                [cur]: "0010",
                [name]: value
            })
            if (validateOnChange){
                validate({ [name]: value })
            }
        }else if(value == "U002"){
            setValues({
                ...values,
                [cur]: "002",
                [name]: value
            })
            if (validateOnChange){
                validate({ [name]: value })
            }
        }else{
            setValues({
                ...values,
                [cur]: "003",
                [name]: value
            })
            if (validateOnChange){
                validate({ [name]: value })
            }
        }
    }
    const handleInputChangeforState = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange){
            validate({ [name]: value })
        }
       
        const config ={
            headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
        }
        axios.get(`cityData/${value}`,config).then(
            response =>{
                setCity(response.data);
                console.log(response.data);
            }
        )
        .catch(error =>{
            alert("City Is Not Awailable Select None");
        })
    }

    const InputEvent = (event) => {
        const { name, value } = event.target;
    
        setUserData({
            ...data,
            [name]: value
        })
        if (validateOnChange)
            validateUserDetails({ [name]: value })
        
    }

    const handlecourierTobeArranged = (event) => {
        const { name, value } = event.target;
    
        setCourierTobeArranged({
            ...courierTobeArranged,
            [name]: value
        })
        if (validateOnChange)
            validatecourierTobeArranged({ [name]: value })
        
    }
    
    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})  
    }

    const resetUserData = () => {
        setUserData(initialUserDetails);
        setUserError({})
    }
    const resetCourierTobeArranged = () => {
        setCourierTobeArranged(initialcourierdetails);
        setcourierError({})
    }

    return {
        values,
        data,
        courierTobeArranged,
        setValues,
        setUserData,
        setCourierTobeArranged,
        errors,
        userError,
        courierError,
        setErrors,
        setUserError,
        setcourierError,
        handleInputChange,
        handleInputChangeforBrand,
        handleInputChangeforAccountName,
        handleInputChangeforCountry,
        handleInputChangeforState,
        InputEvent,
        handlecourierTobeArranged,
        resetForm,
        resetUserData,
        resetCourierTobeArranged,

    }
}


const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiFormControl-root': {
            width: '80%',
            margin: theme.spacing(1)
        }
    }
}))

export function Form(props) {

    const classes = useStyles();
    const { children, ...other } = props;
    return (
        <form className={classes.root} autoComplete="off" {...other}>
            {props.children}
        </form>
    )
}

