import vasantLabelsLogo from '../../images/vasantLabelsLogo.png';
import vasantLabelsLogoPaymentCompleted from '../../images/vasantLabelsLogoPaymentCompleted.png';
import * as employeeService from "../../services/employeeService";
import jsPDFInvoiceTemplate, { OutputType } from "jspdf-invoice-template";

export const profomaInvoiceCreation = async (orderDetail, deliveryAddress) => {
    const today = new Date();
    let selectedFreightOption = orderDetail.selectedFreightOption ? JSON.parse(orderDetail.selectedFreightOption) : {};
    const invoiceProps = {
        outputType: OutputType.Save,
        // outputType:'blob',
        returnJsPDFDocObject: true,
        fileName: `PERFOMA INVOICE NO:${orderDetail.id}`,
        orientationLandscape: false,
        logo: {
            src: (orderDetail?.paymentMode == "ONLINE") ? vasantLabelsLogoPaymentCompleted : vasantLabelsLogo,
            width: 30, //aspect ratio = width/height
            height: 30,
            margin: {
                top: 0, //negative or positive num, from the current position
                left: 0 //negative or positive num, from the current position
            }
        },
        business: {
            name: "VASANTS LABELS PVT.LTD.",
            address: "UNIT NO.A/16,MIDC IND AREA,OLD BHENDIPADA, OPP.JAIN TEMPLE",
            phone: "AMBERNATH(W),THANE-421501  GST NO.:27AAACV1308D1ZU",
            email: "9890234128/7410056001",
            email_1: "info@vasantslabels.com",
            website: "www.vasantslabels.com",
        },
        contact: {
            label: "Profoma Invoice Issued For:",
            name: `${deliveryAddress.acname}`,
            address: `${deliveryAddress.acaddr1} ${deliveryAddress.acaddr2}`,
            phone: `${deliveryAddress.acaddr3} ${deliveryAddress.acaddr4}`,
            // email_1:`${deliveryAddress.citynm ? deliveryAddress.citynm : deliveryAddress.city} ${deliveryAddress.statenm? deliveryAddress.statenm : deliveryAddress.state} ${deliveryAddress.countrynm? deliveryAddress.countrynm : deliveryAddress.country} ${deliveryAddress.zip}`,
            email: `${orderDetail.mobileNo}`,
            otherInfo: `${orderDetail.email}`,
        },
        invoice: {
            label: "Profoma Invoice #: ",
            num: orderDetail.id,
            invDate: `Order Date: ${orderDetail.createdAt}`,
            invGenDate: `Profoma Invoice Date: ${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`,
            headerBorder: false,
            tableBodyBorder: false,
            header: [
                { "title": "#", "style": { "width": 10 } },
                { "title": "Item", "style": { "width": 50 } },
                { "title": "Size", "style": { "width": 20 } },
                { "title": "Price", "style": { "width": 20 } },
                { "title": "Quantity", "style": { "width": 20 } },
                { "title": "Total", "style": { "width": 20 } },
                { "title": "Tax", "style": { "width": 30 } },
                { "title": "Sub-Total", "style": { "width": 30 } }
            ],
            table: orderDetail.orderitems.map((item, index) => (
                [
                    index + 1,
                    item.docno,
                    item.selectedSize,
                    item.rate,
                    item.quantity,
                    employeeService.getRounOffValue(item.finalRate),
                    orderDetail.itemTaxType.includes("igst") ? `igst:${item.igst === 0 ? 0 : Math.round((item.igst * 100) / (item.finalRate))}%:${employeeService.getRounOffValue(item.igst)}` : `sgst:${item.sgst === 0 ? 0 : Math.round((item.sgst * 100) / (item.finalRate))}%:${employeeService.getRounOffValue(item.sgst)} cgst:${item.cgst === 0 ? 0 : Math.round((item.cgst * 100) / (item.finalRate))}%:${employeeService.getRounOffValue(item.cgst)}`,
                    employeeService.getRounOffValue(item.finalRateWithTax)
                ]
            )),

            additionalRows: [{
                col1: 'Total:',
                col2: `${employeeService.getRounOffValue(orderDetail.total)}`,
                col3: '',
                style: {
                    fontSize: 14 //optional, default 12
                }
            },
            {
                col1: 'Shipping:',
                col2: `${employeeService.getRounOffValue(selectedFreightOption.charges ?? 0)}`,
                col3: '',
                style: {
                    fontSize: 10 //optional, default 12
                }
            },
            {
                col1: 'GST:',
                col2: `${employeeService.getRounOffValue((orderDetail.subTotal - orderDetail.total) + ((orderDetail.freightCharges ?? 0) - (selectedFreightOption.charges ?? 0)))}`,
                col3: '',
                style: {
                    fontSize: 10 //optional, default 12
                }
            },
            {
                col1: 'SubTotal:',
                col2: `${employeeService.getRounOffValue(orderDetail.subTotal + (orderDetail.freightCharges ?? 0))}`,
                col3: '',
                style: {
                    fontSize: 10 //optional, default 12
                }
            }],

            // invTotalLabel: "Total:",
            // invTotal: `${employeeService.getRounOffValue(orderDetail.total)}`,
            // invCurrency: "",
            // row1: {
            //     col1: 'GST:',
            //     col2: `${employeeService.getRounOffValue(orderDetail.subTotal - orderDetail.total)}`,
            //     col3: '',
            //     style: {
            //         fontSize: 10 //optional, default 12
            //     }
            // },
            // row2: {
            //     col1: 'SubTotal:',
            //     col2: `${employeeService.getRounOffValue(orderDetail.total + (orderDetail.subTotal - orderDetail.total))}`,
            //     col3: '',
            //     style: {
            //         fontSize: 10 //optional, default 12
            //     }
            // },
            invDescLabel: "Note",
            invDesc: `If profoma is approved pleae arrange payment in the name of "Vasants Labels Pvt.Ltd" by Demand Draft or Multi city cheque or payment at per cheque of Nationalised Bank of current date only or You can deposit your cheque/cash in your city in the name of"vasants Labels pvt,Ltd."at
HDFC Bank (A/c No:50200035774841, IFSC:HDFC0000146)
and email bank slip immediately so we can start production and despatch good at earliest`,
        },
        footer: {
            text: "For VASANTS LABELS PVT.LTD.",
        },
        pageEnable: true,
        pageLabel: "Page ",
    };
    if (orderDetail.showQuestionnaire && orderDetail.orderitems?.find(obj => obj.questionnaire)) {
        let questionnaireArray = [
            {
                col1: '',
                col2: '',
                col3: ''
            },
            {
                col1: 'User Provided Questions/Answers',
                col2: '',
                col3: '',
                style: {
                    fontSize: 14 //optional, default 12
                }
            },
            {
                col1: 'Item',
                col2: 'Question',
                col3: 'Answer',
                style: {
                    fontSize: 10 //optional, default 12
                }
            }
        ];
        orderDetail.orderitems?.forEach(orderItemObject => {
            if(orderItemObject.questionnaire) {
                JSON.parse(orderItemObject.questionnaire)?.map((questionObject, index) => {
                    questionnaireArray.push({
                        col1: orderItemObject.docno + (orderItemObject.selectedSize ? ` ${orderItemObject.selectedSize}` : ''),
                        col2: questionObject.question ? questionObject.question : '',
                        col3: questionObject.userAnswer ? questionObject.userAnswer : '',
                        style: {
                            fontSize: 7,
                            fontColor:'grey'
                        }
                    });
                });
            }
        });
        invoiceProps.invoice.additionalRows = [...invoiceProps.invoice.additionalRows, ...questionnaireArray];
    }
    const pdfCreated = jsPDFInvoiceTemplate({ ...invoiceProps });
    await pdfCreated.jsPDFDocObject;

};
