import React,{useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Spinner from "../../components/spinner/spinner.component";
import CategoriesData from './CategoriesData';
// import {connect} from 'react-redux';
import Header from '../../components/Header';
// import Dropdown from './Dropdown';
import axios from 'axios';
// import ProductsInfo from '../../components/ProductsInfo/ProductsInfo';
import Button from '../../components/controls/Button';
import { categoryArray } from '../../constants/VlplConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setRootCategory } from '../../store/slices/shop/ShopSlice';
// import {selectShoppingDataBasedOnGroup} from '../../redux/shop/shop.selectors';
// import {createStructuredSelector} from 'reselect';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));




export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles();

   /** redux */
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  const [spinnerActive, setSpinnerActive] = useState(true);
  const [value, setValue] = React.useState(0);
  const [rootCategoryValue, setRootCategoryValue] = React.useState(0);
  const [rootCategoryArray, setRootCategoryArray] = useState(categoryArray);
  const [category,setCategory]=useState([]);
  const [filterFn, setFilterFn] =useState({ fn: items => { return items; } });

  useEffect(() => {
    dispatch(setRootCategory(rootCategoryArray[0].label));
    getCategory(rootCategoryArray[0].label);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRootCategory = (event, newValue) => {
    setRootCategoryValue(newValue);
    setCategory([]);
    let category = rootCategoryArray[newValue];
    setTimeout(() => {
      dispatch(setRootCategory(category?.label));
      if(category?.subCategory?.length) {
        setCategory(category.subCategory);
        setValue(0);
      } else {
        getCategory(category?.label);
      }
    }, 100);
  };
  
  const getCategory = (selectedRootCategory) =>{
      // const brand="BABYHUG";
     const config = {
         headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
     }
     axios.get(`getCategory`,(!(props.displayVlplItems || selectedRootCategory === rootCategoryArray[0].label)) && config).then(
         response =>{
           setCategory(response.data);
           setValue(0);
           let rootCategoryObject = {...rootCategoryArray};
           rootCategoryObject.forEach(obj=> {
            if(obj.label === selectedRootCategory) {
              obj.subCategory = response.data;
            }
           });
           setRootCategoryArray(rootCategoryObject);
           setSpinnerActive(false);
         }
     )
     .catch(error =>{
         setSpinnerActive(false);
     });
     }

     
  return (
  <>
  {!props.displayVlplItems && <Header searchInput={true} setFilterFn={setFilterFn} /> }
  {
    spinnerActive ?
    <Spinner />
    :
    <div className={classes.root}>
      <AppBar position="static" color="default">
      <Tabs
          value={rootCategoryValue}
          onChange={handleChangeRootCategory}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label={rootCategoryArray[0].label} />
          {
            (currentUser?.roles?.length && !currentUser?.roles?.find(roleObject=> roleObject.name === "User"))  ?
             <Tab label={rootCategoryArray[1].label} /> : ''
          }
        </Tabs>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {
            category!=null? 
              category.map((val, ind) => (
                <Tab key={ind} label={val} />
                    ))
             :null
          }
        {/* <button onClick={getImages}>Vasants Labels</button>  */}
        </Tabs>
      </AppBar>
      {category.map((val, ind) => (
        value===ind ? <CategoriesData displayVlplItems={props.displayVlplItems || rootCategoryArray[rootCategoryValue]?.label === rootCategoryArray[0].label} infletedRateApply={props.infletedRateApply} index={ind} key={ind} filterFn={filterFn} cat={category[ind]} selectedRootCategory={rootCategoryArray[rootCategoryValue]} /> : null 
       ))
      }
    </div>
  }
    
    
  </>
  );
}



























// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: '100%',
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

//  function ScrollableTabsButtonAuto({props,shoppingData}) {
  
//   const classes = useStyles();
//   const [value, setValue] = React.useState(shoppingData);
//   const [data,setData]=useState();
//   // const {infletedRateApply} = props;

  
//   // const[data,setData]=useState([]);
//   const [category,setCategory]=useState([]);
//   const [filterFn, setFilterFn] =useState({ fn: items => { return items; } });
 

//   useEffect(()=>{
// // const filteredList = props.products.shoppingData.filter(prod=> 
// //         value && prod.groupname=="print labels" || 
// //         value && prod.groupname=="heat transfer" );
// //     setValue(filteredList);
//     getCategory();
// },[]);

//   const handleChange = (newValue) => {
//     // setValue(newValue);
//     // console.log(value)
//   // console.log(value);
//   //  const brand="BABYHUG";
//   //  const groupname="paper sticker";
//   //     const config ={
//   //        headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
//   //    }
//   //    axios.get(`getproducts/${brand}/${groupname}`,config).then(
//   //        response =>{
//   //          setValue(response.data);
//   //            console.log(response.data);
//   //        }
//   //    )
//   //    .catch(error =>{
//   //        alert("City Is Not Awailable Select None");
//   //    })
     
//   }; 
// const getCategory = () =>{
//   const brand="BABYHUG";
//    const config ={
//      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
//  }
//  axios.get(`getCategory/${brand}`,config).then(
//      response =>{
//        setCategory(response.data);
//          console.log(response.data);
//      }
//  )
//  .catch(error =>{
//      alert("City Is Not Awailable Select None");
//  })
//  }
//   return (
//   <>
  
//   <Header searchInput={true} setFilterFn={setFilterFn} />
 
//     <div className={classes.root}>
//       <AppBar position="static" color="default">
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           indicatorColor="primary"
//           textColor="primary"
//           variant="scrollable"
//           scrollButtons="auto"
//           aria-label="scrollable auto tabs example"
//         >
//           { 
//             category!=null? 
//               category.map((val, ind) => (
               
//                 <Tab key={ind} label={val.grpname}/>
//                     ))
//              :null
//           }
//           {/* <button onClick={clickOnVasants}>VASANTS LABELS</button> */}
//           {/* <Tab label="HOME"/>
//           <Tab label="Item Two"  />
//           <Tab label="Item Three"  />
//           <Tab label="Item Four"  />
//           <Tab label="Item Five"  />
//           <Tab label="Item Six"  />
//           <Tab label="Item Seven"  />
//           <Tab label="Item Four"  />
//           <Tab label="Item Five"  />
//           <Tab label="Item Six"  />
//           <Tab label="Item Seven"  /> */}
//         </Tabs> 
//       </AppBar>
      
//       {category.map((val, ind) => (
//         value===ind ? <CollectionPreview infletedRateApply={props.infletedRateApply} key={ind} filterFn={filterFn}/> : null 
//        ))
//       }

//       {/* {category!=null ? <div>{value===0 ? <CollectionPreview category={Category}/> : null }</div>:null} */}

//       {/* <TabPanel value={value} index={0}>
        
//       </TabPanel>
//       <TabPanel value={value} index={1}>
//       <Navigate
//               to="/login/searchAllProducts"
//       />
//         Item Two
//       </TabPanel>
//       <TabPanel value={value} index={2}>
//         Item Three
//       </TabPanel>
//       <TabPanel value={value} index={3}>
//         Item Four
//       </TabPanel>
//       <TabPanel value={value} index={4}>
//         Item Five
//       </TabPanel>
//       <TabPanel value={value} index={5}>
//         Item Six
//       </TabPanel>
//       <TabPanel value={value} index={6}>
//         Item Seven
//       </TabPanel> */}
//     </div>
    
//   </>
//   );
// }



// export default ScrollableTabsButtonAuto;
