import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';


import App from "./App";
import "./index.css";

import axios from "axios";
import store from "./store";
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Spinner from "./components/spinner/spinner.component";

// Define a theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Default primary color
    },
    secondary: {
      main: '#f50057', // Default secondary color
    },
    error: {
      main: '#f44336', // Default error color
    },
    warning: {
      main: '#ff9800', // Default warning color
    },
    info: {
      main: '#2196f3', // Default info color
    },
    success: {
      main: '#4caf50', // Default success color
    },
    // You can define other custom colors as needed
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
});


// axios.defaults.baseURL='http://localhost:8080/';
// axios.defaults.baseURL='https://brandcare.biz/zuulgatwayproxy/';
// axios.defaults.baseURL='http://103.195.246.60/onlineOrderingApp/';
axios.defaults.baseURL='https://vlpl.site/onlineOrderingApp/';
// axios.defaults.baseURL='http://36.255.254.195/onlineOrderingApp/';
// axios.defaults.baseURL='https://vlpl.site/zuulgatwayproxy/';
// axios.defaults.headers.common['Authorization']='Bearer ' + localStorage.getItem('token');
createRoot(document.getElementById('root')).render(
  <>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <Router>
              {/* <PersistGate loading={null} persistor={persistor}> */}
              <App />
              {/* </PersistGate> */}
            </Router>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  </>
  );
