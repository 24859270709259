import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";

import axios from "axios";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";


import About from "./About";
import Contact from "./Contact";
import Navbar from "./Navbar";
import Service from "./Service";

import Footer from "./Footer";
import MyPdfViewer from './components/TearmsAndConditionPolicy/MyPdfViewer';

import SignIn from "./components/sign-in/sign-in.component";
import SigninAdmin from './components/sign-in/sign-in.component.Admin';
import SignUp from "./components/sign-up/sign-up.component";


import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './components/Admin_DashBoard/Dashboard';
import DownloadLinkInvoice from './components/InvoiceCreation/DownloadLinkInvoice';
import Popup from './components/Popup';
// import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import Spinner from "./components/spinner/spinner.component";
import BrandBuyer from "./pages/brandBuyerForm/BrandBuyer";
import { addItemToCartItems } from './store/slices/cart/CartSlice';
import { selectCartTotal } from './store/slices/cart/cart.selectors';
import { addUser } from './store/slices/user/UserSlice';
import { setShoppingData } from './store/slices/shop/ShopSlice';
import ProductDetailPage from './pages/productDetailpage/productDetailPage.component';
import LandingPage from './components/vlplHome/LandingPage';
const Checkout = lazy(() => import('./pages/checkout/Checkout'));
const CheckoutPage = lazy(() => import('./pages/checkout/checkout.component'));
const Home = lazy(() => import('./Home'));
const Main = lazy(() => import('./pages/Main_Home/Main'));
// const {productDetail} from './pages/productDetailpage/productDetailPage.component';
// const ProductDetailPage = lazy(()=> import('./pages/productDetailpage/productDetailPage.component'));
// const OrderConfirm = lazy(() => import( './pages/Order/OrderConfirm'));
const OrderDetail =lazy(() => import( './pages/Order/OrderDetail'));
const ProfilePage = lazy(() => import('./pages/profile/profile.component'));

const App = ({}) => {

  /** redux */
  const currentUser = useSelector((state) => state.users.currentUser);
  const zeroValueCartItems = useSelector((state) => state.cart.zeroValueCartItems);
  const total = useSelector(selectCartTotal);
  const dispatch = useDispatch();

  const [spinnerActive, setSpinnerActive] = useState(false);
  const [orderConformation, setOrderConformation] = useState(false);
  const [cityNotApplyInflation,setCity]=useState(["V018","V001","T040","C038","C031","B148","B001"]);
  const[user,setUser]=useState(null);
 
  const [role, setRole]= useState(null);
  const [infletedRateApply,setinfletedRateApply]=useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [redirectNavigation, setRedirectNavigation] = useState("");
 
  useEffect( () => {
    getStartData();
  },[user]); 
  
  const fetchCartDetails = async() => {
    const config ={
      headers:{ Authorization: 'Bearer ' + localStorage.getItem('token') }
    }
    await axios.get(`endUser/cartDetails`,config)
      .then(response => {
        if(response && response.data) {
          response.data.forEach(item=> {
            dispatch(addItemToCartItems(item));
          });
        }
      }).catch(e=> {
        console.log(e);
        return [];
      });
  }
  const getStartData = async () => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      const config = {
        headers: { Authorization: 'Bearer ' + token }
      }
      axios.get(`user`, config).then((response) => {
        if (!response) {
          dispatch(setShoppingData([]));
        }
        dispatch(addUser(response.data));
        if (response.data?.acmastDetails?.find(acmast => cityNotApplyInflation.includes(acmast.city))) {
          setinfletedRateApply(false);
        }
        setRole(response.data?.roles[0]?.name);
        setSpinnerActive(false);
        fetchCartDetails();
      })
        .catch(error => {
          // console.log(errors);
          dispatch(setShoppingData([]));
          setSpinnerActive(false);
        });
    } else {
      setSpinnerActive(false);
    }
  }
  
  const checkToken=()=>{
    const token= localStorage.getItem('token');
    if(token){
      return true;
    }else{
      return false;
    }
  }

  return (
    <>
      {/* {(currentUser!==null) ? (currentUser.roles[0].name!='Admin'? <Header />: null) : <Navbar /> } */}

      {
        spinnerActive ?
          <Spinner />
          :
          <>
            {/* {(currentUser !== null) ? null : <Navbar />} */}
            {/* <Router> */}
              <Routes>
              <Route
                exact
                path="/"
                element={
                  currentUser ? (
                    <Main infletedRateApply={infletedRateApply} />
                  ) : (
                    <LandingPage infletedRateApply={infletedRateApply}/>
                  )
                }/>
                {/* <Route exact path="/" render={() => (currentUser !== null) ? (<Main infletedRateApply={infletedRateApply} />) : (<Home />)} /> */}
                <Route exact path="/checkout" element={<CheckoutPage setRedirectNavigation={setRedirectNavigation} />} />
                <Route exact path="/checkout/order" element={(total > 0 || orderConformation || zeroValueCartItems?.length) ? <Checkout orderConformation={orderConformation} setOrderConformation={setOrderConformation}/> : <Navigate to="/" />} />
                {/* <Route exact path="/checkout/orderConfirm" element={<OrderConfirm />} /> */}
                <Route exact path="/orders" element={<OrderDetail />} />
                <Route exact path="/productDetail/:id" element={<ProductDetailPage />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/service" element={<Service />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/forgotPassword" element={<ForgotPassword />} />
                <Route exact path="/signin/adAdmin" element={(currentUser == null) ? <SigninAdmin setUser={setUser} setSpinnerActive={setSpinnerActive} /> : <Navigate to="/" />} />
                <Route exact path="/signin/user" element={(currentUser == null) ? <SignIn setRedirectNavigation={setRedirectNavigation} redirectNavigation={redirectNavigation} url="authenticate" setUser={setUser} setSpinnerActive={setSpinnerActive} /> : <Navigate to="/" />} />
                <Route exact path="/signin/brandBuyer" element={(currentUser == null) ? <SignIn url="authenticateBuyer" /> : <Navigate to="/" />} />
                <Route exact path="/signin/brandBuyerForm" element={(checkToken()) ? <BrandBuyer setUser={setUser} setSpinnerActive={setSpinnerActive} /> : null} />
                <Route exact path="/signup/user" element={(currentUser == null) ? <SignUp url="registerUser" /> : <Navigate to="/" />} />
                <Route exact path="/signup/brandBuyer" element={(currentUser == null) ? <SignUp url="registerBuyer" /> : <Navigate to="/" />} />
                <Route exact path="/adDashboard" element={<Navigate to="/adDashboard/dashboard" />} />
                <Route exact path="/adDashboard/*" element={<Dashboard />} />
                <Route exact path="/profile" element={<Navigate to="/profile/accountSettings" />} />
                <Route exact path="/profile/*" element={<ProfilePage />} />
                <Route path="/profomaInvoice/download/:id" element={<DownloadLinkInvoice />} />
              </Routes>
            {/* </Router> */}
          </>

      }
      <Popup
        title=""
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <MyPdfViewer />
      </Popup>
      <Footer setOpenPopup={setOpenPopup} />
    </>
  );
};



export default (App);

