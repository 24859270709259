import React from "react";
import ScrollableTabsButtonAuto from "../../../pages/Main_Home/ScrollableTabsButtonAuto";
import { Container, Typography } from "@mui/material";

const VlplProducts = ({ infletedRateApply }) => {

    return (
        <>
            <Container
                id="products"
                sx={{
                    pt: { xs: 2 },
                    pb: { xs: 2 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Typography  component="h2" variant="h4" color="text.primary" align="center">
                    Products & Features
                </Typography>
                <Typography  component="h2" variant="h5" color="text.primary" align="center" sx={{ mb:"-32px",pb: { xs: 2 }, }}>
                    Choose a category to view relevant products
                </Typography>
                <ScrollableTabsButtonAuto displayVlplItems={true} infletedRateApply={infletedRateApply} />
            </Container>
        </>
    )
};

export default VlplProducts;