import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { ArrowBackRounded } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.vasantslabels.com/">
        vasantslabels
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  note: {
      paddingTop :theme.spacing(2),
      fontSize: 13,
      color: "#ff1744",
  },
}));

const logoStyle = {
  width: '150px',
  height: 'auto',
};

const SignUp =({url})=> {
  const navigate = useNavigate();
  const classes = useStyles();

    const [data, setData] = useState({
    username: "",
    email:"",
    mobileNo:"",
    password: "",
    confirmPassword: "",
  });

  const [userError,setUserError]= useState({});
  
  const InputEvent = (event) => {
        let validateOnChange= true;
        const { name, value } = event.target

        setData({
            ...data,
            [name]: value
        })
        if (validateOnChange)
        validateDetails({ [name]: value })
  };

  const validateDetails = (fieldValues = data) => {
    let temp = { ...userError }
    
    if ('email' in fieldValues)
        temp.email = (/$^|.+@.+..+/).test(fieldValues.email) && (fieldValues.email.length <=50) ? "" : "Email Is Not Valid."
    if ('username' in fieldValues)
        temp.username = fieldValues.username ? "" : "This field is required."
    if ('password' in fieldValues)
        temp.password = fieldValues.password ? "" : "This field is required."
    if ('confirmPassword' in fieldValues)
        temp.confirmPassword = fieldValues.confirmPassword ? "" : "This field is required."
    if ('mobileNo' in fieldValues)
        temp.mobileNo = (!isNaN(fieldValues.mobileNo) && fieldValues.mobileNo.length == 10) ? "" : "Mobile Number Is Not valid."
    
    setUserError({
        ...temp
    })

    if (fieldValues == data)
        return Object.values(temp).every(x => x == "")
}

    const formSubmit = (e) => {
    e.preventDefault();
    if(data.password===data.confirmPassword){
      const registerData= {
        username: data.username,
        email: data.email,
        mobileNo: data.mobileNo,
        password: data.password
      }
      axios.post(`/${url}`,registerData).then(
        response =>{
          if(url=="registerUser"){
            if(response.data==true){
                alert("You have Sccessfully Register please login!!!!..");
                navigate("/signin/user");
            }else{
                alert("Account Already Exist Use Different Username OR E-mail ⛔️");
            }
          }else{
            if(response.data==true){
                alert("You have Sccessfully Register please login!!!!..");
                navigate("/signin/brandBuyer");
            }else{
                alert("Account Already Exist Use Different Username OR E-mail ⛔️");
            }
          }
             
        }
      )
      .catch(
        error =>{
          alert("Technical Error !!!");
        }
      );
    }else{
      alert("Your password and confirmation password do not match 😕")
    }
    
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
      <Button
          startIcon={<ArrowBackRounded />}
          component="a"
          onClick={()=>{navigate("/");}}
          sx={{ alignSelf: 'start' }}
        >
          Back to "Home"
        </Button>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <InputLabel className={classes.note} >pls note down this Login Id And Password for future use</InputLabel>
        <form className={classes.form} onSubmit={formSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="username"
                variant="standard"
                required
                fullWidth
                id="username"
                label="Login Id"
                value={data.username}
                onChange={InputEvent}
                error={userError.username}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={InputEvent}
                error={userError.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                required
                fullWidth
                id="mobileNo"
                label="Mobile NO"
                name="mobileNo"
                value={data.mobileNo}
                onChange={InputEvent}
                error={userError.mobileNo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={data.password}
                onChange={InputEvent}
                error={userError.password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="confirmPassword"
                id="confirmPassword"
                value={data.confirmPassword}
                onChange={InputEvent}
                error={userError.confirmPassword}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
                {
                    url=="registerUser" ?
                    (
                    <NavLink to="/signin/user" variant="body2">
                    Already have an account? Sign in
                    </NavLink>
                    )
                    : 
                    (
                    <NavLink to="/signin/brandBuyer" variant="body2">
                    Already have an account? Sign in
                    </NavLink>
                    )
                }
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default (SignUp);